import Modal from '@mui/material/Modal';
import Icons from 'Icons';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyButton from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import useScrollLock from 'hooks/useScrollLock';
import React, { useCallback, useRef } from 'react';
import coalesceClassNames, { extendClassName } from 'utils/coalesceClassNames';
import './MyModal.scss';

export default function MyModal({
    isOpen = true,
    close,
    children,
    className,
    header,
    mobileTitle,
    showCloseButton = true,
    fullHeight = false,
    isLoading = false,
    isError = false,
    containerSelector = '#modal-root',
    animateIn = true,
    disableBackgroundClose = false,
}: {
    isOpen?: boolean;
    close?: () => void;
    children?: React.ReactFragment;
    className?: string;
    header?: React.ReactFragment;
    mobileTitle?: React.ReactFragment;
    showCloseButton?: boolean;
    fullHeight?: boolean;
    isLoading?: boolean;
    isError?: boolean;
    containerSelector?: string;
    animateIn?: boolean;
    disableBackgroundClose?: boolean;
}) {
    const handleClose = useCallback(
        (e: React.UIEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
            if (disableBackgroundClose && reason === 'backdropClick') {
                return;
            }
            close?.();
        },
        [close, disableBackgroundClose],
    );

    // To close the modal via backdrop click
    // You need to both mousedown and mouseup on the scroll container
    // This avoids closing when dragging to highlight text fields etc
    const didMouseDown = useRef(false);
    const handleScrollContainerMouseDown = (e: React.UIEvent) => {
        const target = e.target as Element;
        didMouseDown.current = target.classList.contains('MyModal__ScrollContainer');
    };

    const handleScrollContainerMouseUp = (e: React.UIEvent) => {
        const target = e.target as Element;
        if (didMouseDown.current && target.classList.contains('MyModal__ScrollContainer')) {
            e.stopPropagation();
            handleClose?.(e, 'backdropClick');
        }
    };

    const modalRoot = useRef(document.querySelector(containerSelector));

    useScrollLock();

    return (
        <Modal
            className={coalesceClassNames('MyModal', className)}
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition
            container={modalRoot.current}
            componentsProps={{
                backdrop: {
                    transitionDuration: animateIn ? undefined : 0,
                },
            }}
        >
            <div
                className="MyModal__ScrollContainer"
                onMouseDown={handleScrollContainerMouseDown}
                onMouseUp={handleScrollContainerMouseUp}
            >
                {/* Mobile header - hidden on desktop by css */}
                <div className="MyModal__MobileHeader">
                    <MyButton
                        className="MyModal__MobileHeader__BackButton"
                        IconRight={Icons.ChevronLeft}
                        onClick={close}
                        buttonType="None"
                    />
                    {mobileTitle && <h1 className="MyModal__MobileHeader__Title">{mobileTitle}</h1>}
                </div>

                <div
                    className={coalesceClassNames(
                        'MyModal__Frame',
                        className && extendClassName(className, '__Frame'),
                        fullHeight && 'MyModal__Frame--full-height',
                        isLoading && 'MyModal__Frame--Loading',
                        animateIn && 'MyModal__Frame--animate-in',
                        className && isLoading && extendClassName(className, '__Loading'),
                    )}
                >
                    {isLoading ? (
                        <div className="MyModal__LoadingContainer">
                            <MyLinearProgress />
                        </div>
                    ) : isError ? (
                        <ErrorContent className="MyModal__Error" />
                    ) : (
                        <>
                            {header && (
                                <div
                                    className={coalesceClassNames(
                                        'MyModal__Header',
                                        className && `${className}__Header`,
                                    )}
                                >
                                    {header || <div />}
                                </div>
                            )}
                            <div
                                className={coalesceClassNames(
                                    'MyModal__Body',
                                    className && extendClassName(className, '__Body'),
                                )}
                            >
                                {children}
                            </div>
                        </>
                    )}

                    {showCloseButton && (
                        <MyButton
                            className="MyModal__CloseButton"
                            onClick={close}
                            buttonType="None"
                        >
                            <Icons.Close className="icon" />
                            esc
                        </MyButton>
                    )}
                </div>
            </div>
        </Modal>
    );
}
