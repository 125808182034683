import Icons from 'Icons';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import LinkOrDiv from 'components/LinkOrDiv/LinkOrDiv';
import MyButton from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import naturalSort from 'typescript-natural-sort';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isEmpty } from 'utils/helpers';
import { StrictUnion } from 'utils/typeHelpers';
import './DataTable.scss';

// enable case-insensitive sorting
(naturalSort as any).insensitive = true;

export type DataTableSortDirection = 'ASC' | 'DESC';

type DataTableColumnBase = {
    key: string;
    label: React.ReactNode;
    className?: string;
    width?: string;
    whiteSpace?: 'nowrap';
    align?: 'left' | 'center' | 'right';
    emptyDash?: boolean;
    renderHeader?: () => React.ReactFragment;
};

type DataTableColumnSortable<T, V> = DataTableColumnBase & {
    isSortable?: boolean;
    defaultSort?: DataTableSortDirection;
    getValue: (item: T) => V;
    renderValue?: (val: V, item: T, index: number) => React.ReactNode;
};

type DataTableColumnRenderOnly<T> = DataTableColumnBase & {
    render: (item: T, index: number) => React.ReactNode;
};

export type DataTableColumn<T, V = any> = StrictUnion<
    DataTableColumnRenderOnly<T> | DataTableColumnSortable<T, V>
>;

export function ColumnBuilder<T>() {
    const _columns: DataTableColumn<T, any>[] = [];
    return {
        column<V = any>(col: false | DataTableColumn<T, V>) {
            if (col) {
                _columns.push(col);
            }
            return this;
        },
        build() {
            return _columns;
        },
    };
}

export default function DataTable<T>({
    className,
    columns: columnDefs,
    data,
    onRefresh,
    rowClass,
    rowEndIcon,
    rowIsHighlighted,
    rowLinkTo,
    rowLinkToTarget,
    onRowClick,
    onSortChanged,
    canSelectRows,
    onRowSelectChanged,
    useFrontEndSorting = true,
    sortUrlParam,
    isLoading,
    isError,
    isRefreshing = false,
    emptyState,
    zebra = false,
    showHeader = true,
    useStickyHeader = false,
}: {
    className?: string;
    columns: (DataTableColumn<T> | false)[];
    data?: T[];
    onRefresh?: () => void;
    rowClass?: string | ((item: T) => string);
    rowEndIcon?: React.ReactFragment;
    rowIsHighlighted?: (item: T) => boolean;
    rowLinkTo?: (item: T) => string;
    rowLinkToTarget?: string;
    onRowClick?: (item: T) => void;
    onSortChanged?: (column: DataTableColumn<T>, direction: DataTableSortDirection) => void;
    canSelectRows?: boolean;
    onRowSelectChanged?: (selectedItems: T[]) => void;
    useFrontEndSorting?: boolean;
    sortUrlParam?: string;
    isLoading?: boolean;
    isError?: boolean;
    isRefreshing?: boolean;
    emptyState?: React.ReactFragment;
    zebra?: boolean;
    showHeader?: boolean;
    useStickyHeader?: boolean;
}) {
    // Make a copy of column defs and filter out any falsey ones
    const columns = useMemo(
        (): DataTableColumn<T>[] =>
            columnDefs.reduce((cols, def) => {
                if (def) {
                    cols.push({
                        ...def,
                    });
                }
                return cols;
            }, [] as DataTableColumn<T>[]),
        [columnDefs],
    );

    const [searchParams, setSearchParams] = useSearchParams({});

    const defaultSortColumn = columns.find(c => c.defaultSort) ?? columns.find(c => c.isSortable);
    const defaultSortBy = defaultSortColumn ? `${defaultSortColumn.key}` : '';
    const defaultSortDirection = (defaultSortColumn && defaultSortColumn.defaultSort) ?? 'ASC';

    const [sortBy, setSortBy] = useState(defaultSortBy);
    const [sortDirection, setSortDirection] = useState(defaultSortDirection);
    const [selectedItems, setSelectedItems] = useState<T[]>([]);
    const [isSelectAll, setIsSelectAll] = useState(false);

    const updateSelectedItems = useCallback(
        (items: T[]) => {
            setSelectedItems(items);
            onRowSelectChanged?.(items);
        },
        [onRowSelectChanged],
    );

    const setItemSelected = useCallback(
        (item: T, select: boolean) => {
            const isSelected = selectedItems.includes(item);
            if (!isSelected && select) {
                // select now
                updateSelectedItems([...selectedItems, item]);
            } else if (isSelected && !select) {
                // un-select now
                updateSelectedItems(selectedItems.filter(i => i !== item));
            }
        },
        [updateSelectedItems, selectedItems],
    );

    const toggleSelectAll = useCallback(() => {
        if (data) {
            const all = !isSelectAll;
            setIsSelectAll(all);

            if (all) {
                updateSelectedItems([...data]);
            }
            if (!all) {
                // deselect all
                updateSelectedItems([]);
            }
        }
    }, [data, isSelectAll, updateSelectedItems]);

    /** clear selections if data is updated
     * we might want to revisit this later to keep selections
     */
    useEffect(() => {
        updateSelectedItems([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    /** Update sorting when url params change */
    useEffect(() => {
        if (sortUrlParam) {
            const [key, dir] = searchParams.get(sortUrlParam)?.split('|') ?? [];
            if (key && (dir === 'ASC' || dir === 'DESC')) {
                setSortBy(key);
                setSortDirection(dir);
            } else {
                setSortBy(defaultSortBy);
                setSortDirection(defaultSortDirection);
            }
        }
    }, [defaultSortBy, defaultSortDirection, searchParams, sortUrlParam]);

    const handleSortByColumn = (col: DataTableColumn<T>) => {
        const direction =
            sortBy === col.key
                ? sortDirection === 'ASC' // reverse current sort dir
                    ? 'DESC'
                    : 'ASC'
                : col.defaultSort ?? 'ASC'; // use col default

        onSortChanged?.(col, direction);

        if (sortUrlParam) {
            // just update url - this will flow on to updating state params automatically
            if (col.key === defaultSortColumn?.key && direction === defaultSortDirection) {
                searchParams.delete(sortUrlParam);
            } else {
                searchParams.set(sortUrlParam, `${col.key}|${direction}`);
            }
            setSearchParams(searchParams, { replace: true });
        } else {
            // jsut update state params
            setSortBy(col.key || '');
            setSortDirection(direction);
        }
    };

    // Handle data sorting
    const [sortedData, setSortedData] = useState([] as T[]);
    useEffect(() => {
        // create a copy of data array so original is not affected
        const _data = data ? [...data] : [];
        if (useFrontEndSorting && sortBy) {
            const sortByCol = columns.find(c => c.key === sortBy);
            if (sortByCol) {
                _data.sort((a, b) => {
                    const aa = sortByCol.getValue ? sortByCol.getValue(a) : '';
                    const bb = sortByCol.getValue ? sortByCol.getValue(b) : '';
                    let result = naturalSort(`${aa ?? ''}`, `${bb ?? ''}`);
                    if (sortDirection === 'DESC') {
                        result = -result;
                    }
                    return result;
                });
            }
        }
        setSortedData(_data);
    }, [useFrontEndSorting, columns, data, sortBy, sortDirection]);

    const isDataEmpty = isEmpty(data);

    return (
        <div
            className={coalesceClassNames(
                'DataTable',
                zebra && 'DataTable--zebra',
                className,
                isDataEmpty && 'DataTable--empty',
                onRefresh &&
                    !rowEndIcon &&
                    columns[columns.length - 1]?.align === 'center' &&
                    'DataTable--refresh-align-fix',
            )}
        >
            {isLoading ? (
                // Loading spinner
                <div className="DataTable__LoadingWrapper">
                    <MyLinearProgress />
                </div>
            ) : isError ? (
                <ErrorContent className="DataTable__Error" />
            ) : isDataEmpty ? (
                // Empty state
                <div className="DataTable__EmptyStateWrapper">
                    {emptyState || (
                        <>
                            <h2>Nothing to display</h2>
                            <p>No results found</p>
                        </>
                    )}
                    {onRefresh && (
                        <RefreshButton
                            onRefresh={onRefresh}
                            isRefreshing={isRefreshing}
                        />
                    )}
                </div>
            ) : (
                // Main data table
                <>
                    {/* Column headers */}
                    {showHeader && (
                        <StickyHeader isSticky={useStickyHeader}>
                            <div className="DataTable__HeaderRow__Columns">
                                {canSelectRows && (
                                    <div className="DataTable__HeaderCell DataTable__HeaderCell--Select">
                                        <button
                                            className={coalesceClassNames(
                                                'DataTable__SelectButton',
                                                isSelectAll ? 'selected' : 'unselected',
                                            )}
                                            onClick={e => {
                                                e.preventDefault();
                                                toggleSelectAll();
                                            }}
                                        >
                                            {isSelectAll ? (
                                                <Icons.Check className="icon" />
                                            ) : (
                                                <div className="icon" />
                                            )}
                                        </button>
                                    </div>
                                )}
                                {columns.map((col, i) => (
                                    <div
                                        key={`DataCol_${col.key || i}`}
                                        className={coalesceClassNames(
                                            'DataTable__HeaderCell',
                                            col.isSortable && 'DataTable__HeaderCell--sortable',
                                            sortBy === col.key &&
                                                `DataTable__HeaderCell--sort-${sortDirection}`,
                                            onRefresh &&
                                                i === columns.length - 1 &&
                                                'DataTable__HeaderCell--with-refresh',
                                            col.key && `DataTable__HeaderCell--${col.key}`,
                                            col.key && `DataTable__Col--${col.key}`,
                                            col.className,
                                        )}
                                        style={{
                                            width: col.width,
                                            whiteSpace: col.whiteSpace,
                                            textAlign: col.align,
                                        }}
                                    >
                                        {col.renderHeader ? (
                                            col.renderHeader()
                                        ) : (
                                            <label
                                                onClick={
                                                    col.isSortable
                                                        ? () => handleSortByColumn(col)
                                                        : undefined
                                                }
                                            >
                                                {col.label}
                                            </label>
                                        )}
                                    </div>
                                ))}
                                {rowEndIcon && (
                                    <div className="DataTable__HeaderCell DataTable__HeaderCell--RowEndIcon"></div>
                                )}
                            </div>
                            {onRefresh && (
                                <RefreshButton
                                    onRefresh={onRefresh}
                                    isRefreshing={isRefreshing}
                                />
                            )}
                        </StickyHeader>
                    )}

                    {/* Data Rows */}
                    <div
                        className={coalesceClassNames(
                            'DataTable__Body',
                            isRefreshing && 'DataTable__Body--Refreshing',
                        )}
                    >
                        {sortedData.map((item, i) => (
                            <DataRow
                                key={i}
                                item={item}
                                index={i}
                                columns={columns}
                                rowClass={rowClass}
                                rowIsHighlighted={rowIsHighlighted}
                                rowEndIcon={rowEndIcon}
                                rowLinkTo={isRefreshing ? undefined : rowLinkTo}
                                rowLinkToTarget={rowLinkToTarget}
                                onRowClick={isRefreshing ? undefined : onRowClick}
                                canSelectRows={canSelectRows ?? false}
                                setItemSelected={setItemSelected}
                                isSelected={selectedItems.includes(item)}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

function RefreshButton({
    onRefresh,
    isRefreshing,
}: {
    onRefresh: () => void;
    isRefreshing: boolean;
}) {
    return (
        <MyButton
            className={coalesceClassNames('DataTable__RefreshButton', isRefreshing && 'refreshing')}
            buttonType="Nude"
            size="small"
            onClick={onRefresh}
            disabled={isRefreshing}
            IconRight={Icons.Refresh}
        />
    );
}
// Sticky header
// uses IntersectionObserver to see when StickyHeaderRuler goes out of the viewport
// then applies the .sticky class so we can style it
function StickyHeader({
    children,
    isSticky,
}: {
    children?: React.ReactFragment;
    isSticky: boolean;
}) {
    const [stuck, setStuck] = useState(false);
    const ref = React.createRef<HTMLDivElement>();

    const checkIntersection = ([e]: any[]) => {
        const isStuck = e.intersectionRatio < 1 && e.boundingClientRect.top < 85;
        setStuck(isStuck);
    };

    useEffect(() => {
        if (!isSticky) {
            return;
        }
        const cachedRef = ref.current;
        const observer = new IntersectionObserver(checkIntersection, {
            threshold: [1],
            // rootMargin: "-85px 0px 0px 0px", // 84px is $topBarHeight
        });
        observer.observe(cachedRef as Element);

        // eslint-disable-next-line consistent-return
        return () => observer.unobserve(cachedRef as Element);
    }, [isSticky, ref]);

    return (
        <div
            className={coalesceClassNames(
                'DataTable__HeaderRow',
                isSticky && 'DataTable__HeaderRow--sticky',
                stuck ? 'DataTable__HeaderRow--stuck' : 'DataTable__HeaderRow--unstuck',
            )}
            ref={ref}
        >
            {children}
        </div>
    );
}

function DataRow<T>({
    item,
    index,
    columns,
    rowClass,
    rowEndIcon,
    rowIsHighlighted: rowIsSelected,
    rowLinkTo,
    rowLinkToTarget,
    onRowClick,
    canSelectRows,
    setItemSelected,
    isSelected,
}: {
    item: T;
    index: number;
    columns: DataTableColumn<T>[];
    rowClass?: string | ((item: T) => string);
    rowEndIcon?: React.ReactFragment;
    rowIsHighlighted?: (item: T) => boolean;
    rowLinkTo?: (item: T) => string;
    rowLinkToTarget?: string;
    onRowClick?: (item: T) => void;
    canSelectRows: boolean;
    setItemSelected: (item: T, select: boolean) => void;
    isSelected: boolean;
}) {
    const href = rowLinkTo?.(item);
    const id = (item as any).id || index;

    return (
        <LinkOrDiv
            key={`DataRow_${id}`}
            className={coalesceClassNames(
                'DataTable__DataRow',
                onRowClick && 'DataTable__DataRow--clickable',
                rowIsSelected?.(item) && 'DataTable__DataRow--highlighted',
                typeof rowClass === 'function' ? rowClass(item) : rowClass,
            )}
            to={href}
            target={rowLinkToTarget}
            onClick={onRowClick ? () => onRowClick(item) : undefined}
        >
            {canSelectRows && (
                <div className="DataTable__DataCell DataTable__DataCell--Select">
                    <button
                        className={coalesceClassNames(
                            'DataTable__SelectButton',
                            isSelected ? 'selected' : 'unselected',
                        )}
                        onClick={e => {
                            e.preventDefault();
                            setItemSelected(item, !isSelected);
                        }}
                    >
                        {isSelected ? <Icons.Check className="icon" /> : <div className="icon" />}
                    </button>
                </div>
            )}
            {columns.map((col, colIdx) => (
                <DataCell
                    key={colIdx}
                    col={col}
                    item={item}
                    className={col.className}
                    rowIndex={index}
                    value={col.getValue ? col.getValue(item) : ''}
                />
            ))}
            {rowEndIcon && (
                <DataCell
                    rowIndex={index}
                    className="DataTable__DataCell--RowEndIcon"
                    item={item}
                    value={rowEndIcon}
                />
            )}
        </LinkOrDiv>
    );
}

function DataCell<T>({
    col,
    className,
    rowIndex,
    item,
    value,
}: {
    col?: DataTableColumn<T>;
    className?: string;
    rowIndex: number;
    item: T;
    value: any;
}) {
    const renderedValue = useMemo(() => {
        if (col?.renderValue) {
            return col.renderValue(value, item, rowIndex);
        }
        if (col?.render) {
            return col.render(item, rowIndex);
        }
        return value;
    }, [col, item, rowIndex, value]);

    return (
        <div
            key={`DataCell_${col?.key || rowIndex}`}
            className={coalesceClassNames(
                'DataTable__DataCell',
                col?.key && `DataTable__DataCell--${col.key}`,
                col?.key && `DataTable__Col--${col.key}`,
                !renderedValue && `DataTable__DataCell--empty`,
                className,
            )}
            style={{
                width: col?.width,
                whiteSpace: col?.whiteSpace,
                textAlign: col?.align,
            }}
        >
            {!renderedValue && col?.emptyDash ? <>&ndash;</> : renderedValue}
        </div>
    );
}
