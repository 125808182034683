import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum QuoteStatus {
    Quote = 'QUOTE',
    Ordered = 'ORDERED',
}

export default QuoteStatus;

const labels: EnumDisplayLabels<typeof QuoteStatus> = {
    Quote: 'Quote',
    Ordered: 'Ordered',
};

export const QuoteStatusDisplay = createDisplayEnum(QuoteStatus, labels);
