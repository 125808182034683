import OrderProductModal from 'features/orders/components/OrderProductModal/OrderProductModal';
import ordersApi from 'features/orders/orders.api';
import purchasesApi from 'features/purchases/purchases.api';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function PurchaseOrderProductPage() {
    usePageTitle('Product');
    const { orderId = '', productId = '' } = useParams();
    return (
        <PurchaseOrderProductPagePure
            key={productId}
            orderId={parseInt(orderId, 10)}
            productId={parseInt(productId, 10)}
        />
    );
}

function PurchaseOrderProductPagePure({
    orderId,
    productId,
}: {
    orderId: number;
    productId: number;
}) {
    const navigate = useNavigate();
    const [urlParams] = useSearchParams();

    const orderQuery = purchasesApi.usePurchaseOrderDetailQuery(orderId);
    const productQuery = ordersApi.useOrderWindowListQuery(orderId);

    const model = useMemo(() => {
        const allProducts = productQuery.currentData?.flatMap(w => w.window_products) ?? undefined;
        return allProducts?.find(p => p.id === productId);
    }, [productId, productQuery.currentData]);
    const optionsQuery = ordersApi.useOrderProductOptionsQuery(
        {
            orderId,
            windowId: model?.order_window_id ?? 0,
            productId: model?.id ?? 0,
        },
        {
            skip: !model,
        },
    );

    useEffect(() => {
        if (productQuery.currentData && !productQuery.isFetching && !model) {
            navigate(`/purchase-orders/${orderId}`);
        }
    }, [
        model,
        navigate,
        productId,
        productQuery.currentData,
        productQuery.data,
        productQuery.isFetching,
        orderId,
    ]);

    return productId ? (
        <>
            <OrderProductModal
                model={model}
                order={orderQuery.data}
                options={optionsQuery.data}
                isLoading={
                    productQuery.isLoading ||
                    optionsQuery.isLoading ||
                    orderQuery.isLoading ||
                    (!model && productQuery.isFetching)
                }
                isError={productQuery.isError || optionsQuery.isError || orderQuery.isError}
                close={() => navigate(`/purchase-orders/${orderId}?${urlParams}`)}
            />
        </>
    ) : null;
}
