import Kanban, { KanbanColumnDefinition } from 'components/Kanban/Kanban';
import dealsApi from 'features/deals/deals.api';
import { Deal } from 'features/deals/models/Deal';
import { WorkflowDetail } from 'features/deals/models/WorkflowDetail';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback } from 'react';
import DealFlagPill from '../DealFlagPill/DealFlagPill';
import WorkflowStatusIcon from '../WorkflowStatusIcon/WorkflowStatusIcon';
import './DealsKanban.scss';

export default function DealsKanban({ workflow }: { workflow: WorkflowDetail }) {
    const columns = workflow.context.statuses.map(
        (status): KanbanColumnDefinition<Deal> => ({
            id: status.id,
            title: (
                <div className="DealsKanban__ColumnTitle">
                    <WorkflowStatusIcon status={status} /> {status.name}
                </div>
            ),
            group: status.phase,
            cardSelector: deal => deal.statusId === status.id,
        }),
    );

    const query = dealsApi.useWorkflowDealListQuery(workflow.id);
    const [moveMutation] = dealsApi.useDealMoveMutation();

    const dialogManager = useDialogManager();

    const handleCardDrop = useCallback(
        async (item: Deal, destColumn: KanbanColumnDefinition<Deal>, after: Deal | undefined) => {
            const result = await moveMutation({
                dealId: item.id,
                currentStatusId: item.statusId,
                currentSortOrder: item.sortOrder,
                targetStatusId: destColumn.id,
                belowId: after?.id ?? null,
                belowSortOrder: after?.sortOrder ?? null,
                workflowId: workflow.id,
            }).unwrap();
            if (result === 'conflict') {
                dialogManager.toast({
                    title: "Oops! That didn't work",
                    icon: 'wave',
                    message: 'We had trouble making that change, please try again',
                });
            }
        },
        [dialogManager, moveMutation, workflow.id],
    );

    return (
        <Kanban
            columns={columns}
            data={query.data}
            renderCard={item => <Card deal={item} />}
            onCardDrop={handleCardDrop}
            cardLinkTo={deal => deal.id}
        />
    );
}

function Card({ deal }: { deal: Deal }) {
    const displayFlags = deal.context.flagValues.filter(flag => flag.showOnCard);

    return (
        <div className="DealsKanban__Card">
            <div className="DealsKanban__Card__Tuid">{deal.tuid}</div>
            <div className="DealsKanban__Card__Customer">{deal.customerName || <>&nbsp;</>}</div>
            {deal.description && (
                <div className="DealsKanban__Card__Description">{deal.description}</div>
            )}
            <div className="DealsKanban__Card__Flags">
                {displayFlags.map(flag => (
                    <DealFlagPill
                        key={flag.workflowFlagId}
                        value={{
                            id: flag.workflowFlagValueId,
                            label: flag.label,
                            appearance: flag.appearance,
                        }}
                        size="small"
                    />
                ))}
            </div>
            {/* <div className="DealsKanban__Card__SalesRep">
                <Icons.Person />
                {deal.salesRep}
            </div> */}
        </div>
    );
}
