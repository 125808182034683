import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

export enum PurchaseOrderLineError {
    InvalidOptions = 'INVALID_OPTIONS',
    OptionsNotSelected = 'OPTIONS_NOT_SELECTED',
    MissingRequiredOptions = 'MISSING_REQUIRED_OPTIONS',
    BadSize = 'BAD_SIZE',
}

export default PurchaseOrderLineError;

const labels: EnumDisplayLabels<typeof PurchaseOrderLineError> = {
    InvalidOptions: 'Invalid options selected.',
    OptionsNotSelected: 'Options not selected yet.',
    MissingRequiredOptions: 'Missing required options.',
    BadSize: 'Specified width/height is not allowed.',
};

export const PurchaseOrderLineErrorDisplay = createDisplayEnum(PurchaseOrderLineError, labels);
