import PageHeader from 'components/PageHeader/PageHeader';
import DocumentsTable from 'features/documents/components/DocumentsTable/DocumentsTable';
import documentsApi from 'features/documents/documents.api';
import React from 'react';
import { Outlet } from 'react-router';
import { usePageTitle } from 'utils/locationHelpers';

export default function DocumentsPage() {
    usePageTitle('Documents');
    const query = documentsApi.useManufacturerDocumentListQuery();
    return (
        <>
            <PageHeader title="Documents" />
            <DocumentsTable
                data={query.data}
                isLoading={query.isLoading}
                isError={query.isError}
                onRefresh={query.refetch}
                isRefreshing={query.isFetching}
                rowLinkTo={doc => doc.url}
                rowLinkToTarget="_blank"
                showFilters={true}
                isManufacturerDocs={true}
                useUrlFilterParams={true}
            />
            <Outlet />
        </>
    );
}
