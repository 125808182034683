import { faker } from '@faker-js/faker';
import { generateTuid } from 'utils/helpers';
import FlagAppearance from '../enums/FlagAppearance';
import WorkflowPhase from '../enums/WorkflowPhase';
import { Deal, DealSchema } from '../models/Deal';
import { DealDetail } from '../models/DealDetail';
import { DealNote } from '../models/DealNote';
import { Workflow } from '../models/Workflow';
import { WorkflowDetail } from '../models/WorkflowDetail';
import { WorkflowFlag } from '../models/WorkflowFlag';

faker.seed(1);

export const fakeFlagDefs: WorkflowFlag[] = [
    {
        id: faker.string.uuid(),
        name: 'Deposit',
        values: [
            {
                id: faker.string.uuid(),
                label: 'None',
                appearance: FlagAppearance.Default,
                showOnCard: false,
                isDefault: true,
            },
            {
                id: faker.string.uuid(),
                label: 'Paid',
                appearance: FlagAppearance.Success,
                showOnCard: true,
                isDefault: false,
            },
            {
                id: faker.string.uuid(),
                label: 'Not paid',
                appearance: FlagAppearance.Warning,
                showOnCard: true,
                isDefault: false,
            },
        ],
    },
    {
        id: faker.string.uuid(),
        name: 'Type',
        values: [
            {
                id: faker.string.uuid(),
                label: 'Residential',
                appearance: FlagAppearance.Success,
                showOnCard: true,
                isDefault: true,
            },
            {
                id: faker.string.uuid(),
                label: 'Commercial',
                appearance: FlagAppearance.Active,
                showOnCard: true,
                isDefault: false,
            },
        ],
    },
    {
        id: faker.string.uuid(),
        name: 'Qualification',
        values: [
            {
                id: faker.string.uuid(),
                label: 'Unqualified',
                appearance: FlagAppearance.Warning,
                showOnCard: true,
                isDefault: true,
            },
            {
                id: faker.string.uuid(),
                label: 'Qualified',
                appearance: FlagAppearance.Success,
                showOnCard: true,
                isDefault: false,
            },
        ],
    },
    {
        id: faker.string.uuid(),
        name: 'Goodness',
        values: [
            {
                id: faker.string.uuid(),
                label: 'Real good',
                appearance: FlagAppearance.Success,
                showOnCard: true,
                isDefault: true,
            },
            {
                id: faker.string.uuid(),
                label: 'Not good',
                appearance: FlagAppearance.Danger,
                showOnCard: true,
                isDefault: false,
            },
        ],
    },
];

export const fakeWorkFlowDetails: WorkflowDetail[] = [
    {
        id: '1',
        name: 'Sales',
        context: {
            statuses: [
                {
                    id: faker.string.uuid(),
                    name: 'Lead',
                    phase: WorkflowPhase.New,
                },
                {
                    id: faker.string.uuid(),
                    name: 'Booked',
                    phase: WorkflowPhase.New,
                },
                {
                    id: faker.string.uuid(),
                    name: 'Follow up',
                    phase: WorkflowPhase.New,
                },
                {
                    id: faker.string.uuid(),
                    name: 'Initial Qualification',
                    phase: WorkflowPhase.New,
                },
                {
                    id: faker.string.uuid(),
                    name: 'Quoting',
                    phase: WorkflowPhase.Quoting,
                },
                {
                    id: faker.string.uuid(),
                    name: 'Quote sent',
                    phase: WorkflowPhase.Quoting,
                },
                {
                    id: faker.string.uuid(),
                    name: 'Quote Review',
                    phase: WorkflowPhase.Quoting,
                },
                {
                    id: faker.string.uuid(),
                    name: 'Signed',
                    phase: WorkflowPhase.Quoting,
                },
                {
                    id: faker.string.uuid(),
                    name: 'Ordered',
                    phase: WorkflowPhase.InProgress,
                },
                {
                    id: faker.string.uuid(),
                    name: 'Received',
                    phase: WorkflowPhase.InProgress,
                },
                {
                    id: faker.string.uuid(),
                    name: 'Install booked',
                    phase: WorkflowPhase.InProgress,
                },
                {
                    id: faker.string.uuid(),
                    name: 'Pre-Installation Check',
                    phase: WorkflowPhase.InProgress,
                },
                {
                    id: faker.string.uuid(),
                    name: 'Closed',
                    phase: WorkflowPhase.Closed,
                },
                {
                    id: faker.string.uuid(),
                    name: 'Post-Installation Review',
                    phase: WorkflowPhase.Closed,
                },
                {
                    id: faker.string.uuid(),
                    name: 'Feedback Collection',
                    phase: WorkflowPhase.Closed,
                },
                {
                    id: faker.string.uuid(),
                    name: 'Cancelled',
                    phase: WorkflowPhase.Cancelled,
                },
            ],
            flags: fakeFlagDefs,
        },
    },
];

export const fakeWorkflows: Workflow[] = fakeWorkFlowDetails.map(w => {
    // strip props to convert WorkflowDetail to Workflow
    return {
        id: w.id,
        name: w.name,
    };
});

export function generateFakeDealDetail(workflowId?: string): DealDetail {
    const workflow = workflowId
        ? fakeWorkFlowDetails.find(w => w.id === workflowId)
        : faker.helpers.arrayElement(fakeWorkFlowDetails);

    if (!workflow) {
        throw new Error(`failed to find workflow with id '${workflowId}'`);
    }

    const status = faker.helpers.arrayElement(workflow.context.statuses);
    return {
        id: faker.string.uuid(),
        tuid: generateTuid('S'),
        workflowId: workflow.id,
        // salesRep: faker.person.firstName(),
        customerName: faker.person.fullName(),
        description: faker.location.streetAddress(),
        statusId: status.id,
        sortOrder: faker.string.alpha({ length: 3 }),
        context: {
            status,
            workflow,
            flagValues: fakeFlagDefs.map(def => {
                const val = faker.helpers.arrayElement(def.values);
                return {
                    workflowFlagId: def.id,
                    workflowFlagName: def.name,
                    workflowFlagValueId: val.id,
                    label: val.label,
                    appearance: val.appearance,
                    showOnCard: val.showOnCard,
                };
            }),
        },
    };
}

export const fakeDealDetails = faker.helpers.multiple(generateFakeDealDetail, { count: 100 });

export function getFakeDealsList(workflowId: string): Deal[] {
    return fakeDealDetails
        .filter(d => d.workflowId === workflowId && !d.isArchived)
        .map<Deal>(d => DealSchema.parse(d));
}

const generateFakeDealNote = (): DealNote => {
    const user = {
        id: faker.number.int(),
        name: faker.person.fullName(),
    };

    return {
        id: faker.string.uuid(),
        content: faker.lorem.sentence(),
        createdAt: faker.date.recent().toISOString(),
        createdBy: user.id,
        updatedAt: null,
        updatedBy: null,
        context: {
            createdBy: user,
            updatedBy: null,
        },
    };
};

export const fakeDealNotes = fakeDealDetails.reduce((hash, d) => {
    hash[d.id] = faker.helpers.multiple(generateFakeDealNote, {
        count: faker.number.int({ min: 2, max: 10 }),
    });
    return hash;
}, {} as Record<string, DealNote[]>);
