import { z } from 'zod';

const BaseProductOptionSchema = z.object({
    id: z.number(),
    value: z.string(),
    quantity: z.number().optional().nullable(),
    price: z.number().optional().nullable(),
});

export const NamedOptionSchema = BaseProductOptionSchema.extend({
    name: z.string().nullable(),
});

export const SubOptionSchema = BaseProductOptionSchema.extend({
    parentId: z.number().nullable(),
});

export const FabricTrimLiningOptionSchema = z.object({
    itemFabricWidth: z.number().nullable(),
    itemFabricRepeat: z.string().nullable(),
    itemName: z.string().nullable(),
    brandName: z.string().nullable(),
    color: z.string().nullable(),
});

export const ProductOptionSchema = z.intersection(
    BaseProductOptionSchema,
    z.union([NamedOptionSchema, SubOptionSchema, FabricTrimLiningOptionSchema]),
);

export type ProductOption = z.infer<typeof ProductOptionSchema>;
