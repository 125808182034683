import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import { ManufacturerOrder } from 'features/purchases/models/ManufacturerOrder';
import { useBreakpoints } from 'providers/Breakpoints';
import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatDateRelative } from 'utils/dateHelpers';
import { ManufacturerOrderStatusBadge } from '../ManufacturerOrderStatusBadge/ManufacturerOrderStatusBadge';

export default function SupplierOrdersTable({
    purchaseOrderId,
    orders,
    isError,
    isLoading,
}: {
    purchaseOrderId: number;
    orders?: ManufacturerOrder[];
    isError?: boolean;
    isLoading?: boolean;
}) {
    const breakpoints = useBreakpoints();
    const [urlParams] = useSearchParams();

    const columns = useMemo(
        () =>
            ColumnBuilder<ManufacturerOrder>()
                .column({
                    key: 'manufacturer_reference',
                    label: 'Reference',
                    isSortable: true,
                    getValue: item => item.reference,
                    whiteSpace: 'nowrap',
                })
                .column({
                    key: 'order_window_product_ids',
                    label: 'Products',
                    isSortable: true,
                    getValue: item => item.context.orderLines.length,
                })
                .column(
                    breakpoints.isSmallUp && {
                        key: 'eta',
                        label: 'ETA',
                        isSortable: true,
                        getValue: item => item.eta,
                        renderValue: value => (value ? formatDateRelative(value) : '-'),
                    },
                )
                .column({
                    key: 'status',
                    label: 'Status',
                    isSortable: true,
                    getValue: item => item.context.orderStatus.name,
                    renderValue: (val, item) => (
                        <ManufacturerOrderStatusBadge
                            manufacturerOrder={item}
                            showTooltip="full"
                        />
                    ),
                })
                .build(),
        [breakpoints.isSmallUp],
    );

    return (
        <div>
            <DataTable
                className="SupplierOrdersTable"
                isLoading={isLoading}
                isError={isError}
                data={orders}
                columns={columns}
                rowLinkTo={o =>
                    `/purchase-orders/${purchaseOrderId}/supplier-order/${o.id}?${urlParams}`
                }
            />
        </div>
    );
}
