import MyEditModal from 'components/MyEditModal/MyEditModal';
import { OrderWindowProduct } from 'features/orders/models/OrderWindowProduct';
import ordersApi from 'features/orders/orders.api';
import React from 'react';
import OrderProductEditFields from '../OrderProductEditFields/OrderProductEditFields';
import ProductPathDisplay from '../ProductPathDisplay/ProductPathDisplay';
import './OrderProductEditModal.scss';

export default function OrderProductEditModal({
    model,
    close,
}: {
    model: OrderWindowProduct;
    close?: () => void;
}) {
    const [saveMutation, saveMutationState] = ordersApi.useOrderProductUpdateMutation();

    const save = async (editModel: OrderWindowProduct) => {
        await saveMutation(editModel).unwrap();
        close?.();
    };

    return (
        <MyEditModal
            className="OrderProductEditModal"
            title="Edit Product"
            subtitle={<ProductPathDisplay product={model} />}
            mobileTitle={'Product'}
            model={model}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            editImmediately={true}
            fullHeight={false}
        >
            {({ editModel, updateField, isSaving }) => (
                <OrderProductEditFields
                    editModel={editModel}
                    updateField={updateField}
                    isSaving={isSaving}
                />
            )}
        </MyEditModal>
    );
}
