import PurchaseOrderStatus from 'features/purchases/enums/PurchaseOrderStatus';
import { PendingUpdatesSchema } from 'models/PendingUpdates';
import { dateTimeInstant } from 'utils/dateSchemas';
import z from 'zod';

export const QuoteDetailSchema = z
    .object({
        id: z.string(),
        legacyId: z.number(),
        externalId: z.string(),
        tuid: z.string(),
        createdAt: dateTimeInstant(),
        description: z.string(),
        isReadOnly: z.boolean(),
        totalItems: z.number(),
        totalSellPriceEx: z.number(),
        totalSellPriceInc: z.number(),
        totalCostPriceEx: z.number(),
        totalCostPriceInc: z.number(),
        isArchived: z.boolean(),
        overrideTotal: z.number().nullable(),
        context: z.object({
            deal: z.object({
                id: z.string(),
                tuid: z.string(),
                workflowId: z.string(),
                customerName: z.string(),
                description: z.string(),
                statusId: z.string(),
                sortOrder: z.string(),
                isArchived: z.boolean().optional(),
            }),
            workflow: z.object({
                id: z.string(),
                name: z.string(),
            }),
            purchaseOrders: z
                .array(
                    z.object({
                        id: z.number(),
                        tuid: z.string(),
                        status: z.nativeEnum(PurchaseOrderStatus),
                        isArchived: z.boolean(),
                        manufacturerId: z.number(),
                        context: z.object({
                            manufacturer: z.object({
                                id: z.string(),
                                name: z.string(),
                            }),
                        }),
                    }),
                )
                .nullable(),
            pendingUpdates: PendingUpdatesSchema.nullable(),
        }),
    })
    .transform(data => ({
        ...data,
        isQuote: true as const,
        isPurchase: false as const,
    }));

export type QuoteDetail = z.infer<typeof QuoteDetailSchema>;
