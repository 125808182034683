import Icons from 'Icons';
import MyButton, { MyButtonLinkNewTab } from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import { OrderWindow } from 'features/orders/models/OrderWindow';
import { QuoteDetail } from 'features/quotes/models/QuoteDetail';
import quotesApi from 'features/quotes/quotes.api';
import { useBreakpoints } from 'providers/Breakpoints';
import React, { useCallback, useEffect, useState } from 'react';
import './QuotePdfModal.scss';

export default function QuotePdfModal({
    model,
    close,
}: {
    model: QuoteDetail;
    windows: OrderWindow[];
    close?: () => void;
}) {
    const breakpoints = useBreakpoints();

    const [generatePdfQuery] = quotesApi.useLazyQuoteGeneratePdfQuery();
    const [pdfUrl, setPdfUrl] = useState<string>();

    const [isIframeLoading, setIsIframeLoading] = useState(true);

    const generatePdf = useCallback(async () => {
        const url = await generatePdfQuery({
            orderId: model.legacyId,
        }).unwrap();
        setPdfUrl(url);
    }, [generatePdfQuery, model.legacyId]);

    useEffect(() => {
        generatePdf();
    }, [generatePdf]);

    return (
        <MyModal
            className="QuotePdfModal"
            close={close}
            mobileTitle="Quote"
            fullHeight
            header={
                <div className="QuotePdfModal__Header__Inner">
                    <PageHeader
                        className="QuotePdfModal__Header__PageHeader"
                        title="Quote PDF"
                        titleContext={model?.tuid}
                    />

                    <MyButton
                        label="Download PDF"
                        buttonType="Hollow"
                        href={pdfUrl}
                        LinkComponent={MyButtonLinkNewTab}
                        IconLeft={Icons.Download}
                        fullWidth={breakpoints.isVerySmallOnly}
                    />
                </div>
            }
        >
            <div className="QuotePdfModal__RenderPdf">
                {isIframeLoading && (
                    <div className="QuotePdfModal__RenderPdf__Loading">
                        <MyLinearProgress delay={0} />
                    </div>
                )}
                {pdfUrl && (
                    <iframe
                        className="QuotePdfModal__RenderPdf__ContentFrame"
                        src={pdfUrl ? `${pdfUrl}#toolbar=0` : undefined}
                        onLoad={() => setIsIframeLoading(false)}
                    />
                )}

                <div className="QuotePdfModal__RenderPdf__Footer">
                    <MyButton
                        label="Close"
                        buttonType="Hollow"
                        onClick={close}
                    />
                </div>
            </div>
        </MyModal>
    );
}
