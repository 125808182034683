import { z } from 'zod';
import PurchaseOrderLineError from '../enums/PurchaseOrderLineError';
import { ProductConfigurationSchema } from './ProductConfiguration';

export const PurchaseOrderLineSchema = z.object({
    id: z.number(),
    windowGroupName: z.string().nullable(),
    orderWindowId: z.number(),
    quantity: z.number(),
    costPrice: z.number(),
    notes: z.string().nullable().optional(),
    context: z.object({
        configuration: ProductConfigurationSchema,
        errors: z.array(z.nativeEnum(PurchaseOrderLineError)).default([]),
        product: z.object({
            id: z.number(),
            name: z.string(),
            hasWidth: z.boolean(),
            hasHeight: z.boolean(),
            hasQuantity: z.boolean(),
            context: z.object({
                categoryName: z.string(),
                brandName: z.string(),
            }),
        }),
    }),
});

export type PurchaseOrderLine = z.infer<typeof PurchaseOrderLineSchema>;
