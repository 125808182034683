import { ProductDimensionDisplay } from 'features/orders/components/ProductDimensionDisplay/ProductDimensionDisplay';
import { validateProducts } from 'features/orders/helpers/orderValidationHelper';
import { OrderWindowProduct } from 'features/orders/models/OrderWindowProduct';
import React, { useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { formatCurrency } from 'utils/helpers';
import './QuoteProductRow.scss';

export function QuoteProductRow({ product, to }: { product: OrderWindowProduct; to?: string }) {
    const optionsString = useMemo(
        () => product.product_option_selects.map(s => s.name).join(', '),
        [product.product_option_selects],
    );
    const [urlParams] = useSearchParams();

    const errors = useMemo(() => validateProducts([product]), [product]);

    return (
        <Link
            className="QuoteProductRow"
            to={to ?? `products/${product.id}?${urlParams}`}
        >
            {/* Right section contain price/quantity 
            It is floated right to allow other text to wrap around it
            For this reason it needs to appear first in the dom */}
            <div className="QuoteProductRow__Right">
                <div className="QuoteProductRow__Right__Price">
                    {formatCurrency(product.final_price.discounted_retail_price)}
                </div>
            </div>

            <div className="QuoteProductRow__Main">
                {product.details && (
                    <>
                        {/* When details is set, use it as the main label */}
                        <div className="QuoteProductRow__Main__GroupName">{product.details}</div>
                    </>
                )}

                <div className="QuoteProductRow__Main__ProductName">
                    {product.brands_name}: {product.master_products_name ?? product.products_name}
                </div>
                <div className="QuoteProductRow__Main__Badges">
                    <ProductDimensionDisplay product={product} />

                    {product.has_quantity === 1 && product.quantity > 0 && (
                        <div className="QuoteProductRow__Main__Quantity">
                            <label>Qty:</label> {product.quantity}
                        </div>
                    )}
                </div>

                {optionsString && (
                    <div className="QuoteProductRow__Main__Options">{optionsString}</div>
                )}
                {product.notes && (
                    <div className="QuoteProductRow__Main__Notes">{product.notes}</div>
                )}

                {/* Validation errors */}
                {errors.length > 0 && (
                    <div className="QuoteProductRow__Main__ValidationErrors">
                        {errors.map((err, index) => (
                            <div
                                key={index}
                                className="QuoteProductRow__Main__ValidationErrors__Error"
                            >
                                {err.message}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Link>
    );
}
