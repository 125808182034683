import { z } from 'zod';
import { ProductOptionSchema } from './ProductOption';

export const ProductConfigurationSchema = z.object({
    width: z.number(),
    height: z.number(),
    options: z.array(ProductOptionSchema),
});

export type ProductConfiguration = z.infer<typeof ProductConfigurationSchema>;
