import { authHandlers } from 'features/auth/auth.mocks';
import { dashboardHandlers } from 'features/dashboard/dashboard.mocks';
import { dealHandlers } from 'features/deals/deals.mocks';
import { ordersHandlers } from 'features/orders/orders.mocks';
import { purchasesHandlers } from 'features/purchases/purchases.mocks';
import { quotesHandlers } from 'features/quotes/quotes.mocks';
import { settingsHandlers } from 'features/settings/settings.mocks';

export const handlers = [
    ...authHandlers,
    ...dashboardHandlers,
    ...dealHandlers,
    ...ordersHandlers,
    ...purchasesHandlers,
    ...quotesHandlers,
    ...settingsHandlers,
];
