import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditAutocomplete from 'components/PropertyEditAutocomplete/PropertyEditAutocomplete';
import PropertyEditNumber from 'components/PropertyEditNumber/PropertyEditNumber';
import PropertyEditNumpad from 'components/PropertyEditNumpad/PropertyEditNumpad';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import { useWindowGroupOptions } from 'features/orders/helpers/orderProductHelper';
import { OrderWindowProduct } from 'features/orders/models/OrderWindowProduct';
import ordersApi from 'features/orders/orders.api';
import MeasureSystem from 'features/settings/enums/MeasureSystem';
import { selectCurrentCountry } from 'features/settings/settings.slice';
import React, { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import './OrderProductEditFields.scss';

export default function OrderProductEditFields({
    editModel,
    isSaving,
    updateField,
}: {
    editModel: Partial<OrderWindowProduct>;
    isSaving: boolean;
    updateField: (data: Partial<OrderWindowProduct>) => void;
}) {
    const labelOptions = useWindowGroupOptions(editModel.order_id);
    const detailQuery = ordersApi.useProductDetailQuery(editModel.product_id ?? 0, {
        skip: !editModel.product_id,
    });

    /** A grid of width and height measurements (upper-bound) which are valid for this product */
    const priceGrids = useMemo(
        () => detailQuery.data?.price_grids ?? [],
        [detailQuery.data?.price_grids],
    );

    /** Size is valid if the grid contains any records where the width and height
     * are greater than or equal to the current width and height
     * grid width/height of 0 means no limit (width-based charts or height-based charts)
     */
    const isSizeValid = useMemo(() => {
        if (priceGrids.length === 0) {
            return true;
        }

        const match = priceGrids.find(
            grid =>
                (grid.width === 0 || grid.width >= (editModel.width ?? 0)) &&
                (grid.height === 0 || grid.height >= (editModel.height ?? 0)),
        );
        return !!match;
    }, [editModel.height, editModel.width, priceGrids]);

    const country = useAppSelector(selectCurrentCountry);
    const measureUnits = useMemo(
        () => (country?.measure_system === MeasureSystem.Imperial ? 'inches' : 'mm'),
        [country?.measure_system],
    );

    return (
        <div className="OrderProductEditFields">
            <PropertyContainer>
                <PropertyEditAutocomplete
                    label="Window or group name"
                    hint="Create nested groups by using slashes (e.g Building/Room/Window)"
                    freeSolo={true}
                    options={labelOptions}
                    value={editModel.details ?? ''}
                    onChange={details => updateField({ details })}
                    disabled={isSaving}
                />
            </PropertyContainer>
            <PropertyContainer layout="row">
                {editModel.has_width === 1 && (
                    <PropertyEditNumpad
                        label={`Width`}
                        inlineHint={`(${measureUnits})`}
                        value={editModel.width}
                        onChange={width => updateField({ width })}
                        measureSystem={country?.measure_system}
                        allowEighths={!detailQuery.data?.product_brand.is_eight_disabled}
                        allowSixteenths={!detailQuery.data?.product_brand.is_sixteen_disabled}
                        disabled={isSaving}
                        validationRequired={true}
                        validationCustom={!isSizeValid && 'Size is not valid for this product'}
                    />
                )}
                {editModel.has_height === 1 && (
                    <PropertyEditNumpad
                        label={`Height`}
                        inlineHint={`(${measureUnits})`}
                        value={editModel.height}
                        onChange={height => updateField({ height })}
                        measureSystem={country?.measure_system}
                        allowEighths={!detailQuery.data?.product_brand.is_eight_disabled}
                        allowSixteenths={!detailQuery.data?.product_brand.is_sixteen_disabled}
                        disabled={isSaving}
                        validationRequired={true}
                    />
                )}
            </PropertyContainer>
            <PropertyContainer>
                {editModel.has_quantity === 1 && (
                    <PropertyEditNumber
                        label="Quantity"
                        value={editModel.quantity}
                        onChange={val => updateField({ quantity: val ?? 1 })}
                        disabled={isSaving}
                        withButtons
                        min={1}
                    />
                )}
                <PropertyEditText
                    label="Notes (optional)"
                    value={editModel.notes ?? ''}
                    onChange={notes => updateField({ notes })}
                    disabled={isSaving}
                    multiline
                />
            </PropertyContainer>
        </div>
    );
}
