import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';
import typescriptNaturalSort from 'typescript-natural-sort';
import { OrderWindowProduct } from '../models/OrderWindowProduct';
import ordersApi from '../orders.api';

/** Get a comparable string for sorting products by
 * Group name (alphabetical but empty group comes last)
 * Then product name (alphabetical)
 * Then created_at date
 */
const comparisonString = (product: OrderWindowProduct) => {
    // Replace spaces with underscores in names to better sort across tokens
    // eg "Room/Window" would be sorted after "Room 2/Window" normally,
    // Replacing the spaces puts Room_2 after Room without having to consider splitting on the / delimiter
    const groupName = (product.details ?? '').replace(/\s/g, '_');
    const productName =
        product.master_products_name?.replace(/\s/g, '_') ??
        product.products_name.replace(/\s/g, '_');
    const brandName = product.brands_name.replace(/\s/g, '_');
    return `${groupName}||${brandName}||${productName}||${product.created_at}`;
};

export const orderProductSort = (a: OrderWindowProduct, b: OrderWindowProduct) => {
    return typescriptNaturalSort(comparisonString(a), comparisonString(b));
};

/** Get a list of window or group names for the current order
 * for use in the filter dropdown and the typeahead when entering group name
 * This includes all unique entries but also provides heirarchical grouping
 * eg. "Building/Room/Window" will provide options for "Building", "Building/Room", and "Building/Room/Window"
 */
export const useWindowGroupOptions = (orderId?: number) => {
    const productQuery = ordersApi.useOrderWindowListQuery(orderId ?? skipToken);

    const labels = useMemo(() => {
        const tokens = new Set<string>();
        const allProducts = productQuery.currentData?.flatMap(w => w.window_products) ?? [];
        allProducts.sort(orderProductSort);
        allProducts.forEach(product => {
            if (!product.details) {
                // Exclude empty labels from list
                return;
            }
            const productTokens = product.details.split('/');
            productTokens.reduce((accumulator, currentValue) => {
                accumulator = accumulator ? `${accumulator}/${currentValue}` : currentValue;
                tokens.add(accumulator);
                return accumulator;
            }, '');
        });
        return Array.from(tokens.values()).map(l => ({ label: l, value: l }));
    }, [productQuery.currentData]);
    return labels;
};
