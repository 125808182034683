import { faker } from '@faker-js/faker';
import { fakeManufacturers } from 'features/settings/faker/SettingsFaker';
import { generateTuid } from 'utils/helpers';
import ManufacturerOrderCoreStatus from '../enums/ManufacturerOrderCoreStatus';
import PurchaseOrderStatus from '../enums/PurchaseOrderStatus';
import { ManufacturerOrder } from '../models/ManufacturerOrder';
import { ManufacturerOrderLine } from '../models/ManufacturerOrderLine';
import { ProductOption } from '../models/ProductOption';
import { PurchaseOrderDetail } from '../models/PurchaseOrderDetail';
import { PurchaseOrderLine } from '../models/PurchaseOrderLine';

export const fakeManufacturerOrderStatuses: ManufacturerOrder['context']['orderStatus'][] = [
    {
        id: 1,
        coreStatus: ManufacturerOrderCoreStatus.Unstarted,
        name: 'Draft',
    },
    {
        id: 2,
        coreStatus: ManufacturerOrderCoreStatus.Unstarted,
        name: 'Submitted',
    },
    {
        id: 3,
        coreStatus: ManufacturerOrderCoreStatus.InProgress,
        name: 'In Production',
    },
    {
        id: 4,
        coreStatus: ManufacturerOrderCoreStatus.InProgress,
        name: 'Shipped',
    },
    {
        id: 5,
        coreStatus: ManufacturerOrderCoreStatus.Completed,
        name: 'Delivered',
    },
];

const generateFakeOption = (): ProductOption => ({
    id: faker.number.int(),
    value: faker.lorem.word(),
    quantity: faker.number.int({ min: 10, max: 100 }),
    price: faker.number.float({ min: 10, max: 10000, precision: 0.01 }),
    parentId: faker.number.int(),
    name: faker.lorem.word(),
    itemFabricWidth: faker.number.int({ min: 10, max: 100 }),
    itemFabricRepeat: faker.lorem.word(),
    itemName: faker.lorem.word(),
    brandName: faker.lorem.word(),
    color: faker.lorem.word(),
});

const generateFakeOrderLine = (): PurchaseOrderLine => {
    return {
        id: faker.number.int(),
        quantity: faker.number.int({ min: 1, max: 10 }),
        costPrice: faker.number.int({ min: 10, max: 100 }),
        windowGroupName: faker.lorem.sentence(),
        orderWindowId: faker.number.int(),
        notes: faker.lorem.sentence(),

        context: {
            configuration: {
                width: faker.number.int({ min: 10, max: 100 }),
                height: faker.number.int({ min: 10, max: 100 }),
                options: faker.helpers.multiple(generateFakeOption, {
                    count: faker.number.int({ min: 0, max: 20 }),
                }),
            },
            errors: [],
            product: {
                id: faker.number.int(),
                name: faker.commerce.productName(),
                hasWidth: true,
                hasHeight: true,
                hasQuantity: true,
                context: {
                    brandName: faker.company.name(),
                    categoryName: faker.commerce.department(),
                },
            },
        },
    };
};

const generateManufacturerOrderLine = (
    mfOrder: ManufacturerOrder,
    orderLine: PurchaseOrderLine,
): ManufacturerOrderLine => {
    const modifiedOptions = orderLine.context.configuration.options.map(option => {
        return {
            ...option,
            quantity: faker.number.int({ min: 10, max: 100 }),
            price: faker.number.float({ min: 10, max: 10000, precision: 0.01 }),
        };
    });
    return {
        id: faker.number.int(),
        manufacturerOrderId: mfOrder.id,
        externalOrderLineId: orderLine.id,
        notes: faker.helpers.maybe(faker.lorem.sentence) ?? null,
        quantity: orderLine.quantity,
        sellPrice: faker.number.float({ min: 10, max: 10000, precision: 0.01 }),
        context: {
            configuration: {
                ...orderLine.context.configuration,
                options: modifiedOptions,
            },
        },
    };
};

export const generateFakeManufacturerOrder = (po: PurchaseOrderDetail): ManufacturerOrder => {
    const orderStatus = faker.helpers.arrayElement(fakeManufacturerOrderStatuses);
    const mfOrder: ManufacturerOrder = {
        id: faker.number.int(),
        reference: faker.number.int({ min: 1, max: 999 }).toString().padStart(6, '0'),
        freight: faker.number.float({ min: 10, max: 10000, precision: 0.01 }),
        eta: faker.date.future().toISOString().substring(0, 10),
        orderStatusId: orderStatus.id,
        shippingInstructions: faker.lorem.sentence(),
        createdAt: faker.date.recent().toISOString(),
        updatedAt: null,
        context: {
            orderStatus: faker.helpers.arrayElement(fakeManufacturerOrderStatuses),
            shippingAddress: faker.location.streetAddress(),
            shippingMethod: {
                id: faker.number.int(),
                name: faker.lorem.word(),
            },
            orderLines: [], // populated below
        },
    };

    // populate order lines
    mfOrder.context.orderLines = po.context.orderLines.map(ol =>
        generateManufacturerOrderLine(mfOrder, ol),
    );

    return mfOrder;
};

export const generateFakePurchaseOrderDetails = (): PurchaseOrderDetail => {
    const status = faker.helpers.enumValue(PurchaseOrderStatus);
    const orderLines = faker.helpers.multiple(generateFakeOrderLine, {
        count: faker.number.int({ min: 1, max: 20 }),
    });

    const order: PurchaseOrderDetail = {
        id: faker.number.int(),
        tuid: generateTuid('O'),
        manufacturerId: faker.helpers.arrayElement(fakeManufacturers).id,
        sidemark: faker.lorem.word(),
        createdAt: faker.date.recent().toISOString(),
        isArchived: false,
        salesRep: faker.person.firstName(),
        customerDetails: faker.person.bio(),
        totalCost: orderLines.reduce((acc, ol) => acc + ol.costPrice, 0),
        totalQuantity: orderLines.reduce((acc, ol) => acc + ol.quantity, 0),
        notes: faker.lorem.sentence(),
        status,
        context: {
            pendingUpdates: null,
            latestRevision: null,
            parentOrder: null,
            orderDocument: null,
            minRequiredDate: null,
            orderLines,
        },
        isPurchase: true,
        isQuote: false,
        isReadOnly: false,
    };

    // If order is submitted, add some more data
    if (order.status === PurchaseOrderStatus.Submitted) {
        order.context.orderDocument = {
            id: faker.number.int(),
            url: faker.internet.url(),
        };
    }

    // else {
    //     // draft order - generate pendingUpdates data
    //     order.context.pendingUpdates = {
    //         hasPendingUpdates: faker.datatype.boolean(),
    //         orderUpdates: {
    //             hasSurchargesUpdates: faker.datatype.boolean(),
    //             hasFreightUpdates: faker.datatype.boolean(),
    //             hasInstallationUpdates: faker.datatype.boolean(),
    //         },
    //         products: [],
    //     };
    // }
    return order;
};

export const fakePurchaseOrderDetails = faker.helpers.multiple(generateFakePurchaseOrderDetails, {
    count: 400,
});
