import PurchaseOrderStatus, {
    PurchaseOrderStatusDisplay,
} from 'features/purchases/enums/PurchaseOrderStatus';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './PurchaseOrderStatusBadge.scss';

export default function PurchaseOrderStatusBadge({
    status,
    size = 'large',
}: {
    status?: PurchaseOrderStatus;
    size?: 'small' | 'large';
}) {
    return status ? (
        <div
            className={coalesceClassNames(
                'PurchaseOrderStatusBadge',
                `PurchaseOrderStatusBadge--${size}`,
                status,
            )}
        >
            {PurchaseOrderStatusDisplay.display(status)}
        </div>
    ) : null;
}
