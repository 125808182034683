import { Typography } from '@mui/material';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import { DashboardData } from 'features/dashboard/models/DashboardData';
import PurchaseOrderStatus from 'features/purchases/enums/PurchaseOrderStatus';
import QuoteStatus from 'features/quotes/enums/QuoteStatus';
import { selectDealerModules } from 'features/settings/settings.slice';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import CardWidget from '../CardWidget/CardWidget';
import ScoreboardSalesWidget from '../ScoreboardSalesWidget/ScoreboardSalesWidget';
import ScoreboardWidget from '../ScoreboardWidget/ScoreboardWidget';
import './WidgetDisplay.scss';

export default function WidgetDisplay({
    data,
    isLoading,
    isError,
}: {
    data?: DashboardData;
    isLoading?: boolean;
    isError?: boolean;
}) {
    const dealerModules = useAppSelector(selectDealerModules);
    return (
        <div className="WidgetDisplay">
            {isLoading ? (
                // Loading spinner
                <div className="WidgetDisplay__LoadingWrapper">
                    <MyLinearProgress />
                </div>
            ) : isError ? (
                <ErrorContent className="WidgetDisplay__Error" />
            ) : (
                data && (
                    <>
                        <div className="WidgetDisplay__FlexWrapper">
                            {dealerModules?.sales && (
                                <div className="WidgetDisplay__FlexWrapper__CardWrapper">
                                    <CardWidget
                                        category="Sales"
                                        title="Quotes"
                                        count={data.quotes.count}
                                        value={data.quotes.value}
                                        tooltip={
                                            <Typography fontSize={12}>
                                                Total number of unarchived quotes with status: quote
                                            </Typography>
                                        }
                                        to={`/quotes?status=${QuoteStatus.Quote}`}
                                    />
                                    <CardWidget
                                        category="Sales"
                                        title="Ordered"
                                        count={data.sales.count}
                                        value={data.sales.value}
                                        tooltip={
                                            <Typography fontSize={12}>
                                                Total number of unarchived quotes with status:
                                                ordered
                                            </Typography>
                                        }
                                        to={`/quotes?status=${QuoteStatus.Ordered}`}
                                    />
                                </div>
                            )}
                            <div className="WidgetDisplay__FlexWrapper__CardWrapper">
                                <CardWidget
                                    category="Purchases"
                                    title="Drafts"
                                    count={data.draft_orders.count}
                                    value={data.draft_orders.value}
                                    tooltip={
                                        <Typography fontSize={12}>
                                            Total number of unarchived purchase orders with status:
                                            draft
                                        </Typography>
                                    }
                                    to={`/purchase-orders?status=${PurchaseOrderStatus.Draft}`}
                                />
                                <CardWidget
                                    category="Purchases"
                                    title="Submitted"
                                    count={data.purchases.count}
                                    value={data.purchases.value}
                                    tooltip={
                                        <Typography fontSize={12}>
                                            Total number of unarchived purchase orders with status:
                                            submitted
                                        </Typography>
                                    }
                                    to={`/purchase-orders?status=${PurchaseOrderStatus.Submitted}`}
                                />
                            </div>
                        </div>
                        <div className="WidgetDisplay__FlexWrapper">
                            {dealerModules?.sales && (
                                <div className="WidgetDisplay__FlexWrapper__ScoreboardWrapper">
                                    <ScoreboardWidget
                                        title="Top Selling Categories"
                                        formatAsCurrency={true}
                                        rows={data.top_selling_category}
                                        tooltip={
                                            <Typography fontSize={12}>
                                                Total value of products sold (quotes with status:
                                                ordered) by category
                                            </Typography>
                                        }
                                    />
                                </div>
                            )}
                            <div className="WidgetDisplay__FlexWrapper__ScoreboardWrapper">
                                <ScoreboardWidget
                                    title="Most Ordered Categories"
                                    rows={data.most_ordered_category.map(({ id, name, count }) => ({
                                        id,
                                        name,
                                        value: count,
                                    }))}
                                    tooltip={
                                        <Typography fontSize={12}>
                                            Total number of products ordered (purchase orders with
                                            status: submitted) by category
                                        </Typography>
                                    }
                                />
                            </div>
                            {!dealerModules?.sales && (
                                // Placeholder to keep ordered categories scoreboard to half of the flex width on larger layouts
                                <div className="WidgetDisplay__FlexWrapper__ScoreboardWrapper" />
                            )}
                        </div>
                        {dealerModules?.sales && (
                            <div className="WidgetDisplay__FlexWrapper">
                                <div className="WidgetDisplay__FlexWrapper__ScoreboardWrapper">
                                    <ScoreboardSalesWidget rows={data.sales_by_rep} />
                                </div>
                            </div>
                        )}{' '}
                    </>
                )
            )}
        </div>
    );
}

export { WidgetDisplay };
