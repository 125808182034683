import PurchaseOrderCreateModal from 'features/purchases/components/PurchaseOrderCreateModal/PurchaseOrderCreateModal';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function PurchaseOrderCreatePage() {
    usePageTitle('Create');
    const navigate = useNavigate();
    return <PurchaseOrderCreateModal close={() => navigate(`/purchase-orders`)} />;
}
