import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum PurchaseOrderStatus {
    Draft = 'DRAFT',
    Submitted = 'SUBMITTED',
}

export default PurchaseOrderStatus;

const labels: EnumDisplayLabels<typeof PurchaseOrderStatus> = {
    Draft: 'Draft',
    Submitted: 'Submitted',
};

export const PurchaseOrderStatusDisplay = createDisplayEnum(PurchaseOrderStatus, labels);
