import { dateTimeInstant } from 'utils/dateSchemas';
import z from 'zod';

export const QuoteSchema = z
    .object({
        id: z.string(),
        externalId: z.string(),
        tuid: z.string(),
        createdAt: dateTimeInstant(),
        description: z.string(),
        totalItems: z.number(),
        totalSellPriceEx: z.number(),
        totalSellPriceInc: z.number(),
        totalCostPriceEx: z.number(),
        totalCostPriceInc: z.number(),
        isArchived: z.boolean(),
    })
    .transform(data => ({
        ...data,
        isQuote: true as const,
        isPurchase: false as const,
    }));

export type Quote = z.infer<typeof QuoteSchema>;
