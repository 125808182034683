import Icons from 'Icons';
import { OrderWindowProduct } from 'features/orders/models/OrderWindowProduct';
import { Product } from 'features/orders/models/Product';
import React from 'react';
import './ProductPathDisplay.scss';

export default function ProductPathDisplay({
    product,
}: {
    product?: Partial<Product & OrderWindowProduct>;
}) {
    const brandName = product?.brands_name ?? product?.brand_name;
    const categoryName = product?.categories_name ?? product?.category_name;
    const productName = product?.master_products_name ?? product?.products_name ?? product?.name;

    return product ? (
        <div className="ProductPathDisplay">
            {brandName} <Icons.ChevronRight /> {categoryName} <Icons.ChevronRight />{' '}
            <strong>{productName}</strong>
        </div>
    ) : null;
}
