import DataTablePaging from 'components/DataTable/DataTablePaging';
import MyModal from 'components/MyModal/MyModal';
import MyTabs, { TabBuilder } from 'components/MyTabs/MyTabs';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import { ManufacturerOrder } from 'features/purchases/models/ManufacturerOrder';
import { ManufacturerOrderHistoryEntry } from 'features/purchases/models/ManufacturerOrderHistoryEntry';
import purchasesApi from 'features/purchases/purchases.api';
import useDynamicQueryParams from 'hooks/useDynamicQueryParams';
import React, { useMemo, useState } from 'react';
import { formatDateRelative, formatDateTimeRelative } from 'utils/dateHelpers';
import ManufacturerOrderHistoryDisplay from '../ManufacturerOrderHistoryDisplay/ManufacturerOrderHistoryDisplay';
import { ManufacturerOrderStatusBadge } from '../ManufacturerOrderStatusBadge/ManufacturerOrderStatusBadge';
import './SupplierOrderModal.scss';

export default function SupplierOrderModal({
    model,
    isLoading,
    isError,
    close,
}: {
    model?: ManufacturerOrder;
    orderId: number;
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    const [tab, setTab] = useState('details');
    const tabs = useMemo(
        () =>
            model
                ? TabBuilder([
                      {
                          name: 'details',
                          label: 'Details',
                          content: <DetailTab model={model} />,
                      },
                      {
                          name: 'history',
                          label: 'History',
                          content: <HistoryTab model={model} />,
                      },
                  ])
                : [],
        [model],
    );

    return (
        <MyModal
            className="SupplierOrderModal"
            close={close}
            isLoading={isLoading}
            isError={isError}
            mobileTitle="Supplier Order"
            header={
                <PageHeader
                    title="Supplier Order"
                    titleContext={model?.reference}
                />
            }
        >
            <MyTabs
                activeTab={tab}
                tabs={tabs}
                setActiveTab={setTab}
            />
        </MyModal>
    );
}

function DetailTab({ model }: { model: ManufacturerOrder }) {
    return (
        <>
            <PropertyContainer layout="table">
                <PropertyDisplay
                    label="Status"
                    value={
                        <div className="SupplierOrderModal__Status">
                            <ManufacturerOrderStatusBadge
                                manufacturerOrder={model}
                                showTooltip="statusOnly"
                                size="large"
                            />
                        </div>
                    }
                />

                <PropertyDisplay
                    label="ETA"
                    value={model.eta && formatDateRelative(model.eta)}
                />

                <PropertyDisplay
                    label="Notes"
                    verticalAlign="top"
                    value={model.customerNotes}
                />

                <PropertyDisplay
                    label="Last updated"
                    verticalAlign="top"
                    value={formatDateTimeRelative(model.updatedAt ?? model.createdAt)}
                />
            </PropertyContainer>

            {/* TODO: Maybe show products here? TBD */}
            {/* <>
                <div>
                    <h2>Products</h2>
                </div>
                {orderQuery.data &&
                    orderLines?.map(
                        ol =>
                            orderQuery.data && (
                                <PurchaseOrderProductRow
                                    key={ol.id}
                                    order={orderQuery.data}
                                    orderLine={ol}
                                    to={`/purchase-orders/${orderId}/products/${ol.id}?${urlParams}`}
                                />
                            ),
                    )}
            </> */}
        </>
    );
}

function HistoryTab({ model }: { model: ManufacturerOrder }) {
    const [queryParams, , , paging, setPaging] = useDynamicQueryParams(
        {
            manufacturerOrderId: model.id,
        },
        {
            pageSize: 40,
        },
    );
    const query = purchasesApi.useManufacturerOrderHistoryQuery(queryParams);

    return (
        <div className="SupplierOrderModal__HistoryTab">
            {query.data?.data.map((item: ManufacturerOrderHistoryEntry) => (
                <ManufacturerOrderHistoryDisplay
                    manufacturerId={model.id}
                    key={item.id}
                    item={item}
                />
            ))}
            {(query.data?.data.length ?? 0) > 0 && (
                <DataTablePaging
                    data={paging}
                    totalCount={query.data?.total}
                    onChange={setPaging}
                />
            )}
        </div>
    );
}
