import QuoteStatus, { QuoteStatusDisplay } from 'features/quotes/enums/QuoteStatus';
import { Quote } from 'features/quotes/models/Quote';
import { QuoteDetail } from 'features/quotes/models/QuoteDetail';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './QuoteStatusBadge.scss';

export default function QuoteStatusBadge({
    model,
    size = 'large',
}: {
    model?: Quote | QuoteDetail;
    size?: 'small' | 'large';
}) {
    return model ? (
        <div
            className={coalesceClassNames(
                'QuoteStatusBadge',
                `QuoteStatusBadge--${size}`,
                QuoteStatus.Quote,
            )}
        >
            {QuoteStatusDisplay.display(QuoteStatus.Quote)}
        </div>
    ) : null;
}
