import { PurchaseOrderDetail } from 'features/purchases/models/PurchaseOrderDetail';
import purchasesApi from 'features/purchases/purchases.api';
import React from 'react';
import SupplierOrdersTable from '../SupplierOrdersTable/SupplierOrdersTable';

export default function PurchaseOrderStatusTab({ model }: { model: PurchaseOrderDetail }) {
    const query = purchasesApi.useManufacturerOrdersQuery(model.id);
    return (
        <div className="PurchaseOrderStatusTab">
            <SupplierOrdersTable
                purchaseOrderId={model.id}
                orders={query.data}
            />
        </div>
    );
}
