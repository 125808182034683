import useGetProductManufacturerOrders from 'features/purchases/helpers/useGetProductManufacturerOrders';
import { PurchaseOrderDetail } from 'features/purchases/models/PurchaseOrderDetail';
import React, { useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { formatCurrency } from 'utils/helpers';
import { ManufacturerOrderStatusBadge } from '../ManufacturerOrderStatusBadge/ManufacturerOrderStatusBadge';

import { ProductDimensionDisplay } from 'features/orders/components/ProductDimensionDisplay/ProductDimensionDisplay';
import { PurchaseOrderLineErrorDisplay } from 'features/purchases/enums/PurchaseOrderLineError';
import { PurchaseOrderLine } from 'features/purchases/models/PurchaseOrderLine';
import './PurchaseOrderProductRow.scss';

export function PurchaseOrderProductRow({
    orderLine,
    order,
    to,
    showManufacturerStatus,
}: {
    orderLine: PurchaseOrderLine;
    order: PurchaseOrderDetail;
    to?: string;
    showManufacturerStatus?: boolean;
}) {
    const optionsString = useMemo(
        () => orderLine.context.configuration.options.map(o => o.value).join(', '),
        [orderLine.context.configuration.options],
    );
    const [urlParams] = useSearchParams();

    return (
        <Link
            className="PurchaseOrderProductRow"
            to={to ?? `products/${orderLine.id}?${urlParams}`}
        >
            {/* Right section contain price/quantity and manufacturer status
            It is floated right to allow other text to wrap around it
            For this reason it needs to appear first in the dom */}
            <div className="PurchaseOrderProductRow__Right">
                <div className="PurchaseOrderProductRow__Right__Price">
                    {formatCurrency(orderLine.costPrice)}
                </div>

                {/* Manufacturer order statuses - shows for purchase orders only */}
                {showManufacturerStatus && (
                    <ManufacturerOrderStatuses
                        order={order}
                        productId={orderLine.id}
                    />
                )}
            </div>

            <div className="PurchaseOrderProductRow__Main">
                {orderLine.windowGroupName && (
                    // When details is set, use it as the main label
                    <div className="PurchaseOrderProductRow__Main__GroupName">
                        {orderLine.windowGroupName}
                    </div>
                )}

                <div className="PurchaseOrderProductRow__Main__ProductName">
                    {orderLine.context.product.context.brandName}: {orderLine.context.product.name}
                </div>
                <div className="PurchaseOrderProductRow__Main__Badges">
                    <ProductDimensionDisplay orderLine={orderLine} />

                    {orderLine.quantity > 0 && (
                        <div className="PurchaseOrderProductRow__Main__Quantity">
                            <label>Qty:</label> {orderLine.quantity}
                        </div>
                    )}
                </div>

                {optionsString && (
                    <div className="PurchaseOrderProductRow__Main__Options">{optionsString}</div>
                )}
                {orderLine.notes && (
                    <div className="PurchaseOrderProductRow__Main__Notes">{orderLine.notes}</div>
                )}

                {/* Validation errors */}
                {orderLine.context.errors && (
                    <div className="PurchaseOrderProductRow__Main__ValidationErrors">
                        {orderLine.context.errors.map((err, index) => (
                            <div
                                key={index}
                                className="PurchaseOrderProductRow__Main__ValidationErrors__Error"
                            >
                                {PurchaseOrderLineErrorDisplay.display(err)}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Link>
    );
}

function ManufacturerOrderStatuses({
    order,
    productId,
}: {
    order: PurchaseOrderDetail;
    productId: number;
}) {
    const orders = useGetProductManufacturerOrders({ order, productId });
    if (!orders || orders.length === 0) {
        return null;
    }

    return (
        <div className="PurchaseOrderProductRow__ManufacturerOrderStatuses">
            {orders?.map(mfo => (
                <ManufacturerOrderStatusBadge
                    key={mfo.id}
                    manufacturerOrder={mfo}
                    showTooltip="full"
                />
            ))}
        </div>
    );
}
