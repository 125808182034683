import { CircularProgress } from '@mui/material';
import Icons from 'Icons';
import DataTableFilters, { FilterBuilder } from 'components/DataTableFilters/DataTableFilters';
import MyButton, { MyButtonLinkNewTab } from 'components/MyButton/MyButton';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import OrderProductAddModal from 'features/orders/components/OrderProductAddModal/OrderProductAddModal';
import { useWindowGroupOptions } from 'features/orders/helpers/orderProductHelper';
import ordersApi from 'features/orders/orders.api';
import PurchaseOrderReviewModal from 'features/purchases/components/PurchaseOrderReviewModal/PurchaseOrderReviewModal';
import PurchaseOrderStatus from 'features/purchases/enums/PurchaseOrderStatus';
import { PurchaseOrderDetail } from 'features/purchases/models/PurchaseOrderDetail';
import { PurchaseOrderLine } from 'features/purchases/models/PurchaseOrderLine';
import purchasesApi from 'features/purchases/purchases.api';
import { Manufacturer } from 'features/settings/models/Manufacturer';
import { selectIsMultiSupplier, selectManufacturer } from 'features/settings/settings.slice';
import { useBreakpoints } from 'providers/Breakpoints';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import coalesceClassNames from 'utils/coalesceClassNames';
import { formatCurrency } from 'utils/helpers';
import PurchaseOrderEditModal from '../PurchaseOrderEditModal/PurchaseOrderEditModal';
import { PurchaseOrderProductRow } from '../PurchaseOrderProductRow/PurchaseOrderProductRow';
import PurchaseOrderStatusBadge from '../PurchaseOrderStatusBadge/PurchaseOrderStatusBadge';
import './PurchaseOrderDetailTab.scss';

export default function PurchaseOrderDetailTab({ model }: { model: PurchaseOrderDetail }) {
    const breakpoints = useBreakpoints();

    const [showEditModal, setShowEditModal] = useState(false);
    const [showReviewModal, setShowReviewModal] = useState(false);

    /** Total number of products */
    const totalQuantity = model?.totalQuantity ?? 0;

    /** Calculated total applied to this order (ignorning overrideTotal) */
    const totalPrice = model?.totalCost ?? 0;

    const isMultiSupplier = useAppSelector(selectIsMultiSupplier);
    const manufacturer = useAppSelector(selectManufacturer(model.manufacturerId));
    const isInternalCustomer = manufacturer?.manufacturer_customer_settings?.is_internal;

    return (
        <div className="PurchaseOrderDetailTab">
            <div className="PurchaseOrderDetailTab__DetailsPanel">
                <PropertyContainer layout="table">
                    {isMultiSupplier && (
                        <PropertyDisplay
                            label="Supplier"
                            value={manufacturer?.name}
                        />
                    )}

                    <PropertyDisplay
                        label="Sales rep"
                        value={model.salesRep}
                    />

                    <PropertyDisplay
                        className={coalesceClassNames(
                            'PurchaseOrderDetailTab__DetailsPanel__Sidemark',
                            !model.isReadOnly && 'editable',
                        )}
                        label="Sidemark"
                        value={model.sidemark}
                        verticalAlign="top"
                    />

                    <PropertyDisplay
                        label="Notes"
                        verticalAlign="top"
                        value={model.notes}
                    />

                    <PropertyDisplay
                        label="Status"
                        verticalAlign="top"
                        value={
                            <div className="PurchaseOrderDetailTab__StatusBadges">
                                {model?.isArchived === true && <ArchivedBadge />}
                                <PurchaseOrderStatusBadge status={model.status} />
                                <LinkToQuote model={model} />
                            </div>
                        }
                    />
                </PropertyContainer>

                {/* TODO: Show data per manufacturer order */}
                {model.status === PurchaseOrderStatus.Submitted && (
                    <>
                        {/* <PropertyDisplay
                                label="Required date"
                                value={
                                    model.context.requiredDate
                                        ? formatDateRelative(model.context.requiredDate)
                                        : null
                                }
                            />
                            <PropertyDisplay
                                label="Shipping method"
                                value={model.context.shippingMethod?.name}
                            />
                            <PropertyDisplay
                                label="Shipping address"
                                verticalAlign="top"
                                value={model.context.shippingAddress}
                            />
                            <PropertyDisplay
                                label="Shipping instructions"
                                verticalAlign="top"
                                value={model.context.shippingInstructions}
                            /> 
                            
                            {!isInternalCustomer && (
                                <PropertyDisplay
                                    label="Total freight"
                                    verticalAlign="top"
                                    value={formatCurrency(model.context.freight)}
                                /> 
                            )}
                            
                            */}
                    </>
                )}

                {!model.isReadOnly && (
                    <MyButton
                        className="PurchaseOrderDetailTab__DetailsPanel__EditButton"
                        label="Edit"
                        IconLeft={Icons.Edit}
                        buttonType="Hollow"
                        onClick={() => setShowEditModal(true)}
                    />
                )}
            </div>

            <ProductList
                order={model}
                isReadOnly={model.isReadOnly}
            />

            {totalQuantity > 0 && (
                <div className="PurchaseOrderDetailTab__Footer">
                    <div className="PurchaseOrderDetailTab__Footer__Content">
                        <div className="PurchaseOrderDetailTab__Footer__Content__TotalQuantity">
                            {totalQuantity} {totalQuantity === 1 ? 'product' : 'products'}, total
                        </div>

                        <div className="PurchaseOrderDetailTab__Footer__Content__Price">
                            {formatCurrency(totalPrice)}
                        </div>
                        {!isInternalCustomer && (
                            <TotalFreightCost
                                model={model}
                                manufacturer={manufacturer}
                            />
                        )}
                    </div>
                    {model.status === PurchaseOrderStatus.Submitted ? (
                        <MyButton
                            label="View PDF"
                            buttonType="Hollow"
                            href={model.context.orderDocument?.url}
                            disabled={!model.context.orderDocument}
                            LinkComponent={MyButtonLinkNewTab}
                            IconLeft={Icons.File}
                            fullWidth={breakpoints.isVerySmallOnly}
                        />
                    ) : (
                        <MyButton
                            label="Review Order"
                            buttonType="Primary"
                            disabled={model.isReadOnly || totalQuantity === 0}
                            onClick={() => setShowReviewModal(true)}
                            IconLeft={breakpoints.isVerySmallOnly ? undefined : Icons.Play}
                            fullWidth={breakpoints.isVerySmallOnly}
                        />
                    )}
                </div>
            )}

            {showEditModal && (
                <PurchaseOrderEditModal
                    order={model}
                    close={() => setShowEditModal(false)}
                />
            )}

            {showReviewModal && (
                <PurchaseOrderReviewModal
                    model={model}
                    close={() => setShowReviewModal(false)}
                />
            )}
        </div>
    );
}

function LinkToQuote({ model }: { model: PurchaseOrderDetail }) {
    if (model.context.parentOrder?.isQuote) {
        // Purchase orders link to quote if it came from a quote
        // No link is shown if it came from a draft order
        return (
            <Link
                className="Link PurchaseOrderDetailTab__StatusBadges__OrderLink"
                to={`/quotes/${model.context.parentOrder.id}`}
            >
                View quote
            </Link>
        );
    }
    return null;
}

function TotalFreightCost({
    model,
    manufacturer,
}: {
    model: PurchaseOrderDetail;
    manufacturer?: Manufacturer;
}) {
    const mfOrdersQuery = purchasesApi.useManufacturerOrdersQuery(model.id, {
        skip: model.status === PurchaseOrderStatus.Draft,
    });

    const isSubmitted = model.status === PurchaseOrderStatus.Submitted;
    const shippingMethodId = manufacturer?.customer_settings.default_shipping_method_id ?? null;

    const freightEstimateQuery = ordersApi.useOrderFreightEstimateQuery(
        {
            orderId: model.id,
            shippingMethodId: shippingMethodId ?? 0,
            manufacturerId: manufacturer?.id ?? 0,
        },
        { skip: isSubmitted || !shippingMethodId || !manufacturer?.id },
    );

    const isLoading = freightEstimateQuery.isLoading;

    const totalFreight = useMemo(() => {
        if (!isSubmitted) {
            // use estimate from query
            return freightEstimateQuery.data ?? undefined;
        }

        // else calculate freight from manufacturer orders
        return mfOrdersQuery.data?.reduce((sum, order) => sum + order.freight, 0) ?? 0;
    }, [freightEstimateQuery.data, isSubmitted, mfOrdersQuery.data]);

    return (
        <div className="PurchaseOrderDetailTab__TotalFreightCost">
            {isLoading ? (
                <CircularProgress size={24} />
            ) : totalFreight ? (
                <>
                    {' '}
                    + {formatCurrency(totalFreight)} shipping {!isSubmitted && '(est)'}
                </>
            ) : null}
        </div>
    );
}

function ArchivedBadge() {
    return <div className="PurchaseOrderDetailTab__ArchivedBadge">Archived</div>;
}

export function ProductList({
    order,
    isReadOnly,
}: {
    order: PurchaseOrderDetail;
    isReadOnly?: boolean;
}) {
    const [filteredProducts, setFilteredProducts] = useState<PurchaseOrderLine[]>();
    const [showAddProductModal, setShowAddProductModal] = useState(false);

    const breakpoints = useBreakpoints();

    const labelOptions = useWindowGroupOptions(order.id);
    const filters = useMemo(
        () =>
            FilterBuilder<PurchaseOrderLine>()
                .filter({
                    label: 'Search',
                    type: 'search',
                    getFields: item => {
                        const result = [
                            item.context.product.name,
                            item.context.product.context.brandName,
                            item.windowGroupName,
                        ];
                        return result;
                    },
                })
                .filter({
                    label: 'Filter',
                    type: 'select',
                    options: [
                        {
                            label: 'All products',
                            value: 'all',
                        },
                        {
                            label: 'With errors',
                            value: 'problems',
                        },
                    ],
                    defaultValue: 'all',
                    allowBlank: false,
                    // this isn't used, because we are overriding applyFilter below
                    getField: () => '',
                    applyFilter: (filter, value, item) => {
                        if (value === 'problems') {
                            return item.context.errors.length > 0;
                        }
                        return true;
                    },
                })
                .filter({
                    label: 'Window or group',
                    type: 'select',
                    getField: item => item.windowGroupName,
                    options: [
                        { label: '(None)', value: '__NO_LABEL', isPlaceholder: true },
                        ...labelOptions,
                    ],
                    defaultValue: '',
                    allowBlank: true,
                    applyFilter: (filter, value, item: PurchaseOrderLine) => {
                        if (value === '__NO_LABEL') {
                            return !item.windowGroupName;
                        }
                        return item.windowGroupName?.startsWith(value) ?? false;
                    },
                })
                .build(),
        [labelOptions],
    );

    const currentProductIds = useMemo(
        () => order.context.orderLines.map(p => p.context.product.id),
        [order.context.orderLines],
    );

    return (
        <div className="PurchaseOrderDetailTab__ProductList">
            {order.context.orderLines.length === 0 ? (
                <div className="PurchaseOrderDetailTab__ProductList__Empty">
                    {isReadOnly ? (
                        <label>No products added</label>
                    ) : (
                        <>
                            <label>No products added yet</label>
                            <MyButton
                                label="Add product"
                                IconLeft={Icons.Plus}
                                buttonType="Accent"
                                onClick={() => setShowAddProductModal(true)}
                            />
                        </>
                    )}
                </div>
            ) : (
                <>
                    <div className="PurchaseOrderDetailTab__ProductList__Header">
                        <h2>Products</h2>
                        <div className="PurchaseOrderDetailTab__ProductList__Header__Actions">
                            {!isReadOnly && (
                                <MyButton
                                    className="PurchaseOrderDetailTab__ProductList__Toolbar__AddButton"
                                    label={'Add'}
                                    IconLeft={Icons.Plus}
                                    buttonType="Accent"
                                    onClick={() => setShowAddProductModal(true)}
                                    fullWidth={breakpoints.isVerySmallOnly}
                                />
                            )}
                        </div>
                    </div>
                    <div className="PurchaseOrderDetailTab__ProductList__Toolbar">
                        <DataTableFilters
                            filters={filters}
                            data={order.context.orderLines}
                            onChange={setFilteredProducts}
                        />
                    </div>
                    {(filteredProducts?.length ?? 0) === 0 ? (
                        <div className="PurchaseOrderDetailTab__ProductList__ProductsFilteredOut">
                            No products in this order matched your search.
                        </div>
                    ) : (
                        <div className="PurchaseOrderDetailTab__ProductList__Products">
                            {filteredProducts?.map(p => (
                                <PurchaseOrderProductRow
                                    key={p.id}
                                    order={order}
                                    orderLine={p}
                                    showManufacturerStatus={true}
                                />
                            ))}
                        </div>
                    )}
                </>
            )}

            {showAddProductModal && (
                <OrderProductAddModal
                    orderId={order.id}
                    currentProductIds={currentProductIds}
                    manufacturerId={order.manufacturerId}
                    close={() => setShowAddProductModal(false)}
                />
            )}
        </div>
    );
}
