import { skipToken } from '@reduxjs/toolkit/dist/query';
import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton from 'components/MyButton/MyButton';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import ordersApi from 'features/orders/orders.api';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import { PurchaseOrderDetail } from 'features/purchases/models/PurchaseOrderDetail';
import { QuoteDetail } from 'features/quotes/models/QuoteDetail';
import React, { useCallback, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { StrictUnion } from 'utils/typeHelpers';
import './OrderUpdatesMessagePanel.scss';

export default function OrderUpdatesMessagePanel({
    className,
    model,
}: {
    className?: string;
    model?: StrictUnion<PurchaseOrderDetail | QuoteDetail>;
}) {
    const orderId = model?.legacyId ?? model?.id;
    const windowsQuery = ordersApi.useOrderWindowListQuery(orderId ?? skipToken);
    const [recalcMutation, recalcMutationStatus] = ordersApi.useOrderWindowsRecalculateMutation();

    const applyUpdates = useCallback(async () => {
        const windowIds = windowsQuery.data?.map(w => w.id);
        if (model && orderId && windowIds) {
            await recalcMutation({
                orderId,
                windowIds,
            });
        }
    }, [model, recalcMutation, windowsQuery.data, orderId]);

    const [showDetails, setShowDetails] = useState(false);

    const isApplyingUpdates = recalcMutationStatus.isLoading || recalcMutationStatus.isSuccess;

    return model ? (
        <>
            <MessagePanel
                className={coalesceClassNames('OrderUpdatesMessagePanel', className)}
                messageType="warning"
                title="Updates available"
            >
                <p>
                    This order has pending updates from the supplier that must be applied before it
                    can be submitted or edited further.
                </p>
                <p className="OrderUpdatesMessagePanel__Actions">
                    <a
                        className="Link"
                        onClick={() => setShowDetails(true)}
                    >
                        show details
                    </a>
                    <MyButton
                        label="Update now"
                        buttonType="Accent"
                        isLoading={isApplyingUpdates}
                        onClick={applyUpdates}
                    />
                </p>
            </MessagePanel>

            {showDetails && (
                <DetailsModal
                    model={model}
                    applyUpdates={applyUpdates}
                    isApplyingUpdates={isApplyingUpdates}
                    close={() => setShowDetails(false)}
                />
            )}
        </>
    ) : null;
}

export function DetailsModal({
    model,
    applyUpdates,
    isApplyingUpdates,
    close,
}: {
    model: PurchaseOrderDetail | QuoteDetail;
    applyUpdates: () => void;
    isApplyingUpdates: boolean;
    close?: () => void;
}) {
    const { entityTitle } = useOrderPresentation();

    return (
        <MyModal
            className="OrderUpdatesMessagePanel__DetailsModal"
            mobileTitle={entityTitle}
            close={close}
            header={
                <PageHeader
                    title="Updates Available"
                    subtitle="The following updates are available for this order"
                />
            }
        >
            <ul className="OrderUpdatesMessagePanel__DetailsModal__List">
                {model.context.pendingUpdates?.orderUpdates.hasSurchargesUpdates && (
                    <li>Surcharges updated</li>
                )}
                {model.context.pendingUpdates?.orderUpdates.hasFreightUpdates && (
                    <li>Freight charges updated</li>
                )}
                {model.context.pendingUpdates?.orderUpdates.hasInstallationUpdates && (
                    <li>Installation charges updated</li>
                )}

                {model.context.pendingUpdates?.products.map(p => (
                    <li
                        key={p.productId}
                        className="OrderUpdatesMessagePanel__DetailsModal__ProductRow"
                    >
                        <div className="name">{p.productName}</div>
                        <ul className="updates">
                            {p.hasPricingUpdates && <li>Pricing updated</li>}
                            {p.hasCostPaddingUpdates && <li>Cost padding updated</li>}
                            {p.hasFabricUpdates && <li>Fabric updated</li>}
                            {p.hasFreightUpdates && <li>Freight charges updated</li>}
                            {p.hasInstallationUpdates && <li>Installation charges updated</li>}
                            {p.hasRuleUpdates && <li>Rules updated</li>}
                            {p.hasSurchargesUpdates && <li>Surcharges updated</li>}
                        </ul>
                    </li>
                ))}
            </ul>
            <div className="OrderUpdatesMessagePanel__DetailsModal__Footer">
                <MyButton
                    className="OrderUpdatesMessagePanel__DetailsModal__Footer__ApplyButton"
                    label="Update now"
                    buttonType="Accent"
                    onClick={applyUpdates}
                    isLoading={isApplyingUpdates}
                />
            </div>
        </MyModal>
    );
}
