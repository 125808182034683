import Icons from 'Icons';
import MyButton from 'components/MyButton/MyButton';
import { MyMenuButtonItem } from 'components/MyMenuButton/MyMenuButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import MyModal from 'components/MyModal/MyModal';
import MyTabs, { TabBuilder } from 'components/MyTabs/MyTabs';
import PageHeader from 'components/PageHeader/PageHeader';
import OrderUpdatesMessagePanel from 'features/orders/components/OrderUpdatesMessagePanel/OrderUpdatesMessagePanel';
import { OrderWindow } from 'features/orders/models/OrderWindow';
import { QuoteDetail } from 'features/quotes/models/QuoteDetail';
import quotesApi from 'features/quotes/quotes.api';
import useUrlQueryState from 'hooks/useUrlQueryState';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo, useState } from 'react';
import OrderDocuments from '../../../orders/components/OrderDocuments/OrderDocuments';
import OrderCloneModal from '../QuoteCloneModal/QuoteCloneModal';
import './QuoteDetailModal.scss';
import QuoteDetailTab from './QuoteDetailTab';

export default function QuoteDetailModal({
    model,
    windows,
    isLoading,
    isError,
    close,
}: {
    model?: QuoteDetail;
    windows?: OrderWindow[];
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    const [archiveMutation] = quotesApi.useQuoteArchiveMutation();
    const [unarchiveMutation] = quotesApi.useQuoteUnarchiveMutation();

    const dialogManager = useDialogManager();

    const [showCloneModal, setShowCloneModal] = useState(false);

    const archive = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: `Archive quote`,
            message: `Are you sure you want to archive this quote?`,
            acceptLabel: 'Yes, archive now',
            acceptButtonType: 'Danger',
        });

        if (confirm && model) {
            const result = await dialogManager.showLoadingWhile(archiveMutation(model.legacyId));
            if (!(result as any).error) {
                // close if successful
                close?.();
            }
        }
    }, [archiveMutation, close, dialogManager, model]);

    const unarchive = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: `Un-archive quote`,
            message: `Are you sure you want to un-archive this quote?`,
            acceptLabel: 'Yes, un-archive now',
            acceptButtonType: 'Primary',
        });

        if (confirm && model) {
            await dialogManager.showLoadingWhile(unarchiveMutation(model.legacyId));
        }
    }, [unarchiveMutation, dialogManager, model]);

    const menuItems: (MyMenuButtonItem | false)[] = useMemo(
        () => [
            model?.isArchived
                ? {
                      label: `Un-archive quote`,
                      IconLeft: Icons.Undo,
                      onClick: unarchive,
                  }
                : {
                      label: `Archive quote`,
                      IconLeft: Icons.Archive,
                      onClick: archive,
                  },
        ],
        [archive, model?.isArchived, unarchive],
    );

    const [tab, setTab] = useUrlQueryState('tab', {
        allowedValues: ['details', 'docs'],
        defaultValue: 'details',
    });

    const tabs = useMemo(
        () =>
            model && windows
                ? TabBuilder([
                      {
                          name: 'details',
                          label: 'Details',
                          content: (
                              <QuoteDetailTab
                                  model={model}
                                  windows={windows}
                              />
                          ),
                      },
                      {
                          name: 'docs',
                          label: 'Documents',
                          content: (
                              <OrderDocuments
                                  orderId={model.legacyId}
                                  isReadOnly={model.isArchived}
                              />
                          ),
                      },
                  ])
                : [],
        [model, windows],
    );

    return (
        <MyModal
            className="QuoteDetailModal"
            close={close}
            isLoading={isLoading}
            isError={isError}
            mobileTitle="Quotes"
            header={
                <>
                    <PageHeader
                        className="QuoteDetailModal__PageHeader"
                        title="Quote"
                        titleContext={model?.tuid}
                    >
                        {model && (
                            <>
                                <MyButton
                                    label={`Clone`}
                                    title="Create a copy of this quote"
                                    IconLeft={Icons.Clone}
                                    buttonType="Hollow"
                                    onClick={() => setShowCloneModal(true)}
                                />
                                <MyMenuKebabButton
                                    className="QuoteDetailModal__MenuKebabButton"
                                    menuItems={menuItems}
                                />
                            </>
                        )}
                    </PageHeader>

                    {model?.context.pendingUpdates?.hasPendingUpdates && (
                        <OrderUpdatesMessagePanel
                            className="QuoteDetailModal__OrderUpdatesMessagePanel"
                            model={model}
                        />
                    )}
                </>
            }
        >
            {model && windows && (
                <>
                    <MyTabs
                        activeTab={tab}
                        tabs={tabs}
                        setActiveTab={setTab}
                    />

                    {showCloneModal && (
                        <OrderCloneModal
                            order={model}
                            close={() => setShowCloneModal(false)}
                        />
                    )}
                </>
            )}
        </MyModal>
    );
}
