import MyEditModal from 'components/MyEditModal/MyEditModal';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import { QuoteEditable, QuoteEditableFactory } from 'features/quotes/models/QuoteEditable';
import quotesApi from 'features/quotes/quotes.api';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import './QuoteCreateModal.scss';

export default function QuoteCreateModal({
    dealId,
    close,
}: {
    dealId: string;
    close?: () => void;
}) {
    const navigate = useNavigate();

    const model = useMemo(() => QuoteEditableFactory.create(), []);

    const [saveMutation, saveMutationState] = quotesApi.useQuoteCreateMutation();

    const save = async (editModel: QuoteEditable) => {
        const id = await saveMutation({ dealId, model: editModel }).unwrap();
        navigate(`/quotes/${id}`);
    };

    return (
        <MyEditModal
            className="QuoteCreateModal"
            model={model}
            isLoading={!model}
            editImmediately={true}
            fullHeight={false}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            title="New quote"
            mobileTitle="Quotes"
            saveButtonLabel="Create"
            saveButtonType="Accent"
        >
            {({ editModel, updateField, isSaving }) => (
                <>
                    <PropertyContainer>
                        <PropertyEditText
                            label="Description"
                            value={editModel.description}
                            onChange={description => updateField({ description })}
                            multiline={true}
                            disabled={isSaving}
                        />
                    </PropertyContainer>
                </>
            )}
        </MyEditModal>
    );
}
