import MeasurementDisplay from 'components/MeasurementDisplay/MeasurementDisplay';
import { OrderWindowProduct } from 'features/orders/models/OrderWindowProduct';
import { PurchaseOrderLine } from 'features/purchases/models/PurchaseOrderLine';
import React from 'react';
import { StrictUnion } from 'utils/typeHelpers';
import './ProductDimensionDisplay.scss';

type ProductDimensionDisplayProps = StrictUnion<
    | {
          orderLine: PurchaseOrderLine;
      }
    | {
          product: OrderWindowProduct;
      }
>;

export function ProductDimensionDisplay({ orderLine, product }: ProductDimensionDisplayProps) {
    let hasWidth: boolean;
    let hasHeight: boolean;
    let width: number;
    let height: number;

    if (orderLine) {
        hasWidth = orderLine.context.product.hasWidth;
        hasHeight = orderLine.context.product.hasHeight;
        width = orderLine.context.configuration.width;
        height = orderLine.context.configuration.height;
    } else {
        hasWidth = product.has_width === 1;
        hasHeight = product.has_height === 1;
        width = product.width;
        height = product.height;
    }

    if (hasWidth && hasHeight) {
        return (
            <div className="ProductDimensionDisplay">
                <div className="ProductDimensionDisplay__Badge">
                    <MeasurementDisplay value={width} />
                    &nbsp;&times;&nbsp;
                    <MeasurementDisplay value={height} />
                </div>
            </div>
        );
    }
    if (hasWidth) {
        return (
            <div className="ProductDimensionDisplay">
                <div className="ProductDimensionDisplay__Badge">
                    <MeasurementDisplay
                        value={width}
                        showMetricUnit
                    />
                </div>
            </div>
        );
    }
    if (hasHeight) {
        return (
            <div className="ProductDimensionDisplay">
                <div className="ProductDimensionDisplay__Badge">
                    <MeasurementDisplay
                        value={height}
                        showMetricUnit
                    />
                </div>
            </div>
        );
    }
    return null;
}
