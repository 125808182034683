import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import DataTableFilters, { FilterBuilder } from 'components/DataTableFilters/DataTableFilters';
import documentsApi from 'features/documents/documents.api';
import { DocumentFriendlyTypeDisplay } from 'features/documents/enums/DocumentFriendlyType';
import { DocumentFile } from 'features/documents/models/DocumentFile';
import React, { useMemo, useState } from 'react';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import DocumentFileIcon from '../DocumentFileIcon/DocumentFileIcon';
import './DocumentsTable.scss';

export default function DocumentsTable({
    data,
    isLoading,
    isError,
    onRefresh,
    isRefreshing,
    onRowClick,
    rowLinkTo,
    rowLinkToTarget,
    showFilters,
    isManufacturerDocs = false,
    useUrlFilterParams = false,
}: {
    data?: DocumentFile[];
    isLoading?: boolean;
    isError?: boolean;
    onRefresh?: () => void;
    isRefreshing?: boolean;
    onRowClick?: (item: DocumentFile) => void;
    rowLinkTo?: (item: DocumentFile) => string;
    rowLinkToTarget?: string;
    showFilters?: boolean;
    isManufacturerDocs?: boolean;
    useUrlFilterParams?: boolean;
}) {
    const [filteredData, setFilteredData] = useState<DocumentFile[]>();

    const manufacturersQuery = documentsApi.useManufacturerListQuery();

    const manufacturerOptions = useMemo(
        () => manufacturersQuery.data?.map(m => ({ label: m.name, value: `${m.id}` })) ?? [],
        [manufacturersQuery.data],
    );

    const showManufacturerFields = useMemo(
        () => isManufacturerDocs && manufacturerOptions.length > 1,
        [isManufacturerDocs, manufacturerOptions.length],
    );

    const columns = useMemo(
        () =>
            ColumnBuilder<DocumentFile>()
                .column({
                    key: 'name',
                    label: 'Document name',
                    isSortable: true,
                    getValue: item => item.name,
                    renderValue: (val, doc) => (
                        <>
                            <DocumentFileIcon type={doc.type} />
                            {val}
                        </>
                    ),
                })
                .column({
                    key: 'type',
                    label: 'Type',
                    isSortable: true,
                    getValue: item => item.type,
                    renderValue: val => DocumentFriendlyTypeDisplay.display(val),
                })
                .column(
                    showManufacturerFields && {
                        key: 'manufacturer_name',
                        label: 'Supplier',
                        isSortable: true,
                        getValue: item => item.manufacturer_name,
                    },
                )
                .column({
                    key: 'created_at',
                    label: 'Created',
                    isSortable: true,
                    defaultSort: 'DESC',
                    getValue: item => item.created_at,
                    renderValue: val => formatDateTimeRelative(val),
                })
                .build(),
        [showManufacturerFields],
    );

    const filters = useMemo(
        () =>
            FilterBuilder<DocumentFile>()
                .filter({
                    type: 'search',
                    label: 'Search',
                    getFields: item => [item.name],
                    urlParam: useUrlFilterParams ? 'search' : undefined,
                })
                .filter({
                    type: 'select',
                    label: 'Type',
                    getField: item => item.type,
                    options: DocumentFriendlyTypeDisplay.options,
                    urlParam: useUrlFilterParams ? 'type' : undefined,
                })
                .filter(
                    showManufacturerFields && {
                        type: 'select',
                        label: 'Supplier',
                        getField: item => `${item.manufacturer_id}`,
                        options: manufacturerOptions,
                        urlParam: useUrlFilterParams ? 'supplier' : undefined,
                    },
                )
                .build(),
        [manufacturerOptions, showManufacturerFields, useUrlFilterParams],
    );

    return (
        <>
            {showFilters && (
                <div className="DocumentsTable__FilterBar">
                    <DataTableFilters
                        data={data}
                        onChange={setFilteredData}
                        filters={filters}
                    />
                </div>
            )}
            <DataTable
                className="DocumentsTable__DataTable"
                data={showFilters ? filteredData : data}
                isLoading={isLoading}
                isError={isError}
                columns={columns}
                onRefresh={onRefresh}
                isRefreshing={isRefreshing}
                emptyState="No documents found"
                rowLinkTo={rowLinkTo}
                rowLinkToTarget={rowLinkToTarget}
                onRowClick={onRowClick}
            />
        </>
    );
}
