import { DateTime } from 'luxon';
import { isEmpty } from 'utils/helpers';
import { OrderWindowProduct } from '../models/OrderWindowProduct';

type ProductValidationError = {
    id: number;
    message: string;
    error: 'INVALID_OPTIONS' | 'OPTIONS_NOT_SELECTED' | 'MISSING_REQUIRED_OPTIONS' | 'BAD_SIZE';
};

/** Convert the validation property of OrderWindowProduct to an array of error enums  */
export function validateProducts(products: OrderWindowProduct[]) {
    const errors = products.reduce((arr, product) => {
        if (
            product?.validation?.sixteenth_restriction === 1 ||
            !isEmpty(product?.validation?.oversized_fields)
        ) {
            arr.push({
                id: product.id,
                message: 'Invalid options selected.',
                error: 'INVALID_OPTIONS',
            });
        }
        if (product?.validation?.no_selections_made === 1) {
            arr.push({
                id: product.id,
                message: 'Options not selected yet.',
                error: 'OPTIONS_NOT_SELECTED',
            });
        }
        if (product?.validation?.unanswered_mandatory_fields) {
            arr.push({
                id: product.id,
                message: 'Missing required options.',
                error: 'MISSING_REQUIRED_OPTIONS',
            });
        }
        if (product?.extension.is_oversized > 0) {
            arr.push({
                id: product.id,
                message: 'Specified width/height is not allowed',
                error: 'BAD_SIZE',
            });
        }

        return arr;
    }, [] as ProductValidationError[]);

    return errors;
}

/** Calculate the min allowed value for an order's required date
 * Based on the product_eta_days of all products in the order
 * The result is the largest eta_days + today
 * If no products have an eta then the minimum date is first thing tomorrow
 */
export function calculateMinRequiredDate(products: OrderWindowProduct[]) {
    const today = DateTime.now().startOf('day');

    const maxEta = products
        .map(p => p.product_eta_days)
        .reduce((max: number, eta) => {
            const numEta = eta ?? 0;
            return numEta > max ? numEta : max;
        }, 1);

    return today.plus({ days: maxEta });
}
