import { skipToken } from '@reduxjs/toolkit/dist/query';
import ordersApi from 'features/orders/orders.api';
import OrderPresentation from 'features/orders/providers/OrderPresentation';
import QuoteDetailModal from 'features/quotes/components/QuoteDetailModal/QuoteDetailModal';
import quotesApi from 'features/quotes/quotes.api';
import React, { useCallback } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function DealQuotePage() {
    const { quoteId } = useParams() as { quoteId: string };

    const navigate = useNavigate();

    const orderQuery = quotesApi.useQuoteDetailQuery(quoteId);
    usePageTitle(orderQuery.data?.tuid);
    const windowsQuery = ordersApi.useOrderWindowListQuery(orderQuery.data?.legacyId ?? skipToken);

    const handleClose = useCallback(() => {
        // Clear tab when closing modal
        navigate(`..`);
    }, [navigate]);

    return quoteId ? (
        <OrderPresentation isQuotes={true}>
            <QuoteDetailModal
                model={orderQuery.data}
                windows={windowsQuery.data}
                isLoading={orderQuery.isLoading || windowsQuery.isLoading}
                isError={orderQuery.isError || windowsQuery.isError}
                close={handleClose}
            />
            <Outlet />
        </OrderPresentation>
    ) : null;
}
