import Drawer from '@mui/material/Drawer';
import Icons from 'Icons';
import QuoteriteIconWithNameSvg from 'assets/quoterite-icon-with-name.svg';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import FeatureFlag from 'enums/FeatureFlag';
import dealsApi from 'features/deals/deals.api';
import { selectDealerModules, selectManufacturers } from 'features/settings/settings.slice';
import { useBreakpoints } from 'providers/Breakpoints';
import React from 'react';
import { IconType } from 'react-icons';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import coalesceClassNames from 'utils/coalesceClassNames';
import { useFeatureFlagEnabled } from 'utils/posthogHelpers';
import './MainNav.scss';

export default function MainNav({
    isLoading = false,
    isMenuOpen = false,
    closeMenu,
}: {
    isLoading?: boolean;
    isMenuOpen?: boolean;
    closeMenu?: () => void;
}) {
    const breakpoints = useBreakpoints();
    const dealerModules = useAppSelector(selectDealerModules);
    const manufacturers = useAppSelector(selectManufacturers);

    const isDealsEnabled = !!useFeatureFlagEnabled(FeatureFlag.Deals);
    const shouldLoadWorkflows = dealerModules?.sales && isDealsEnabled;
    const workflowsQuery = dealsApi.useWorkflowListQuery(undefined, {
        skip: !shouldLoadWorkflows,
    });

    return (
        <Drawer
            className="MainNav"
            variant={breakpoints.isSmallDown ? 'temporary' : 'permanent'}
            anchor="left"
            open={isMenuOpen}
            onClose={closeMenu}
        >
            <div className="MainNav__Logo">
                {window.HOSTED_DOMAIN_NAV_LOGO ? (
                    <img
                        className="MainNav__Logo__HostedImage"
                        src={window.HOSTED_DOMAIN_NAV_LOGO}
                    />
                ) : (
                    <img
                        className="MainNav__Logo__DefaultImage"
                        src={QuoteriteIconWithNameSvg}
                    />
                )}
            </div>

            {isLoading ? (
                <MyLinearProgress className="MainNav__LoadingProgress" />
            ) : (
                <div className="MainNav__Nav">
                    <div className="MainNav__Nav__TopSection">
                        <NavSection title="">
                            <NavLinkItem
                                Icon={Icons.Dashboard}
                                label="Dashboard"
                                to={`/dashboard`}
                                onClick={closeMenu}
                            />
                            {isDealsEnabled && (
                                <>
                                    {workflowsQuery.data?.map(workflow => (
                                        <NavLinkItem
                                            key={workflow.id}
                                            Icon={Icons.Quote}
                                            label={workflow.name}
                                            to={`/workflows/${workflow.id}`}
                                            onClick={closeMenu}
                                        />
                                    ))}
                                </>
                            )}

                            <NavLinkItem
                                Icon={Icons.PurchaseOrder}
                                label={dealerModules?.sales ? 'Purchases' : 'Orders'}
                                to={`/purchase-orders`}
                                onClick={closeMenu}
                            />
                            <NavLinkItem
                                Icon={Icons.Documents}
                                label="Documents"
                                to={`/documents`}
                                onClick={closeMenu}
                            />
                        </NavSection>
                    </div>
                    <div className="MainNav__BottomSection">
                        <NavSection title="Settings">
                            <NavLinkItem
                                Icon={Icons.Settings}
                                label="Settings"
                                to={`/settings`}
                                onClick={closeMenu}
                            />
                            <NavLinkItem
                                Icon={Icons.Supplier}
                                label={manufacturers?.length === 1 ? 'Supplier' : 'Suppliers'}
                                to={`/supplier`}
                                onClick={closeMenu}
                            />
                            <NavLinkItem
                                Icon={Icons.Bundle}
                                label="Bundles"
                                to={`/bundles`}
                                onClick={closeMenu}
                            />
                        </NavSection>
                    </div>
                </div>
            )}
        </Drawer>
    );
}

function NavSection({
    title,
    className,
    children,
}: {
    title?: string;
    className?: string;
    children?: React.ReactFragment;
}) {
    return (
        <ul className={coalesceClassNames('MainNav__NavSection', className)}>
            {title && <li className="MainNav__NavSection__Title">{title}</li>}
            {children}
        </ul>
    );
}

function NavLinkItem({
    Icon = Icons.List,
    label = '',
    to = '',
    onClick,
}: {
    Icon?: IconType;
    label: React.ReactNode;
    to: string;
    onClick?: () => void;
}) {
    const location = useLocation();
    const rootPath = `/${location.pathname.split('/')[1]}`;
    const isActive = rootPath === to;

    return (
        <li className="MainNav__NavLinkItem">
            <Link
                className={coalesceClassNames('MainNav__NavLinkItem__Link', isActive && 'active')}
                to={to}
                onClick={onClick}
            >
                {Icon && <Icon className="icon" />}
                <span className="label">{label}</span>
            </Link>
        </li>
    );
}

// function AbsLinkItem({
//     Icon = Icons.List,
//     label,
//     to = '',
// }: {
//     Icon?: IconType;
//     label: React.ReactNode;
//     to: string;
// }) {
//     return (
//         <li className="MainNav__NavLinkItem">
//             <a
//                 className={coalesceClassNames('MainNav__NavLinkItem__Link')}
//                 href={to}
//             >
//                 {Icon && <Icon className="icon" />}
//                 <span className="label">{label}</span>
//             </a>
//         </li>
//     );
// }
