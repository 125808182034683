import MyButton from 'components/MyButton/MyButton';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isEmpty } from 'utils/helpers';
import { StrictUnion } from 'utils/typeHelpers';
import { DataTableFilter, FilterCompProps } from './DataTableFilterTypes';
import './DataTableFilters.scss';
import AutocompleteFilter, {
    applyAutocompleteFilter,
    AutocompleteFilterConfig,
} from './Filters/AutocompleteFilter';
import DateFilter, { applyDateFilter, DateFilterConfig } from './Filters/DateFilter';
import SearchFilter, { applySearchFilter, SearchFilterConfig } from './Filters/SearchFilter';
import SelectFilter, { applySelectFilter, SelectFilterConfig } from './Filters/SelectFilter';
import TextFilter, { applyTextFilter, TextFilterConfig } from './Filters/TextFilter';
import ToggleFilter, { applyToggleFilter, ToggleFilterConfig } from './Filters/ToggleFilter';

/** Union of filter configs for all the available filter types
 * Each filter type can define its own properties in addition to the base properties
 */
export type DataTableFilterConfig<T> = StrictUnion<
    (
        | TextFilterConfig<T>
        | SearchFilterConfig<T>
        | SelectFilterConfig<T>
        | AutocompleteFilterConfig<T>
        | DateFilterConfig<T>
        | ToggleFilterConfig<T>
    ) & {
        // additional base properties that self-reference this type
        // so cant be put in DataTableFilterConfigBase
        /** change event fired whenever the user changes the filter value */
        onChange?: (config: DataTableFilterConfig<T>, value: string) => void;
        /** Custom apply filter function allows the config to override the default filter behavior
         * If set to false, the filter will not be applied
         * this is useful if you want to use the filter UI to do something tricky that isn't actually filtering
         * Then you probably want to implement your own onChange handler instead
         */
        applyFilter?:
            | false
            | ((config: DataTableFilterConfig<T>, value: string, item: T) => boolean);
    }
>;

export function FilterBuilder<T>() {
    const _filters: DataTableFilterConfig<T>[] = [];
    return {
        filter(f: false | DataTableFilterConfig<T>) {
            if (f) {
                _filters.push(f);
            }
            return this;
        },
        build() {
            return _filters;
        },
    };
}

function defaultApplyFilter<T>(config: DataTableFilterConfig<T>, value: string, item: T) {
    switch (config.type) {
        case 'text':
            return applyTextFilter(config, value, item);
        case 'search':
            return applySearchFilter(config, value, item);
        case 'select':
            return applySelectFilter(config, value, item);
        case 'autocomplete':
            return applyAutocompleteFilter(config, value, item);
        case 'date':
            return applyDateFilter(config, value, item);
        case 'toggle':
            return applyToggleFilter(config, value, item);
        default:
            throw new Error(`No default applyFilter function for filter config`, config);
    }
}

export default function DataTableFilters<T>({
    className,
    children,
    data,
    filters: filterDefs,
    onChange,
    allowReset = true,
}: {
    className?: string;
    children?: React.ReactNode;
    data?: T[];
    filters: DataTableFilterConfig<T>[];
    onChange: (filteredData: T[] | undefined) => void;
    allowReset?: boolean;
}) {
    const [urlParams, setUrlParams] = useSearchParams({});
    const updateUrlParam = useCallback(
        (filter: DataTableFilterConfig<T>, val?: string) => {
            if (filter.urlParam) {
                if (!val || val === filter.defaultValue) {
                    urlParams.delete(filter.urlParam);
                } else {
                    urlParams.set(filter.urlParam, val);
                }
            }
        },
        [urlParams],
    );

    const [filteredData, setFilteredData] = useState<T[] | undefined>([]);

    const filters = useMemo(
        () =>
            filterDefs.map(f => {
                const urlParamValue = f.urlParam ? urlParams.get(f.urlParam) : null;
                const result: DataTableFilter<typeof f> = {
                    config: f,
                    isVisible: !!f.isSticky,
                    value: f.defaultValue || '',
                };

                // create a copy of options array for select filters
                if (result.config.type === 'select' || result.config.type === 'autocomplete') {
                    result.config.options = result.config.options ? [...result.config.options] : [];
                    // apply urlParamValue only if a valid option exists
                    // this avoids console errors about out-of-range values
                    if (
                        urlParamValue &&
                        result.config.options.some(o => o.value === urlParamValue)
                    ) {
                        result.value = urlParamValue;
                    }
                } else {
                    // apply urlParamValue
                    result.value = urlParamValue || result.value;
                }

                return result;
            }),
        [filterDefs, urlParams],
    );

    const applyFilters = useCallback(() => {
        const activeFilters = filters.filter(f => !isEmpty(f.value));

        const _filteredData: T[] | undefined = !data
            ? data
            : activeFilters.reduce(
                  (result, filter) => {
                      const func =
                          filter.config.applyFilter === false
                              ? undefined
                              : filter.config.applyFilter ?? defaultApplyFilter;
                      if (func) {
                          result = result.filter(item => func(filter.config, filter.value, item));
                      }
                      return result;
                  },
                  [...data],
              );

        setFilteredData(_filteredData);
        onChange(_filteredData);
    }, [data, filters, onChange]);

    const handleFilterChanged = useCallback(
        (filter: DataTableFilter<DataTableFilterConfig<T>>) => {
            // update url params
            filter.config.onChange?.(filter.config, filter.value);
            updateUrlParam(filter.config, filter.value);
            setUrlParams(urlParams, { replace: true });
            applyFilters();
        },
        [applyFilters, setUrlParams, updateUrlParam, urlParams],
    );

    /** Re-run filters if data changes */
    useEffect(() => {
        applyFilters();
    }, [data, applyFilters]);

    const resetFilters = useCallback(() => {
        filters.forEach(f => {
            f.value = f.config.defaultValue || '';
            updateUrlParam(f.config, f.value);
        });
        setUrlParams(urlParams, { replace: true });
        applyFilters();
    }, [filters, setUrlParams, urlParams, applyFilters, updateUrlParam]);

    const canResetFilters = filters.some(f => (f.value ?? '') !== (f.config.defaultValue ?? ''));

    return (
        <div className={coalesceClassNames('DataTableFilters', className)}>
            <div className="DataTableFilters__Toolbar">
                <div className="DataTableFilters__Filters">
                    {filters.map((f, i) => (
                        <FilterComponent
                            key={i}
                            filter={f}
                            onChange={handleFilterChanged}
                        />
                    ))}
                </div>
                {children}
            </div>

            {allowReset && canResetFilters && (
                <div className="DataTableFilters__FilteredResetPanel">
                    Showing {filteredData?.length ?? 0} of {data?.length ?? 0} items
                    <MyButton
                        className="DataTableFilters__ResetButton"
                        label="Reset filters"
                        buttonType="LinkButton"
                        onClick={resetFilters}
                    />
                </div>
            )}
        </div>
    );
}

function FilterComponent<T>({ filter, onChange }: FilterCompProps<DataTableFilterConfig<T>>) {
    switch (filter.config.type) {
        case 'text':
            return (
                <TextFilter
                    filter={filter as DataTableFilter<TextFilterConfig<T>>}
                    onChange={onChange}
                />
            );
        case 'search':
            return (
                <SearchFilter
                    filter={filter as DataTableFilter<SearchFilterConfig<T>>}
                    onChange={onChange}
                />
            );
        case 'select':
            return (
                <SelectFilter
                    filter={filter as DataTableFilter<SelectFilterConfig<T>>}
                    onChange={onChange}
                />
            );
        case 'autocomplete':
            return (
                <AutocompleteFilter
                    filter={filter as DataTableFilter<AutocompleteFilterConfig<T>>}
                    onChange={onChange}
                />
            );
        case 'date':
            return (
                <DateFilter
                    filter={filter as DataTableFilter<DateFilterConfig<T>>}
                    onChange={onChange}
                />
            );
        case 'toggle':
            return (
                <ToggleFilter
                    filter={filter as DataTableFilter<ToggleFilterConfig<T>>}
                    onChange={onChange}
                />
            );
        default:
            throw new Error('No FilterComponent defined for filter type', filter.config);
    }
}
