import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import MyButton from 'components/MyButton/MyButton';
import { DealDetail } from 'features/deals/models/DealDetail';
import QuoteCreateModal from 'features/quotes/components/QuoteCreateModal/QuoteCreateModal';
import { Quote } from 'features/quotes/models/Quote';
import quotesApi from 'features/quotes/quotes.api';
import Icons from 'Icons';
import React from 'react';

const COLUMNS = ColumnBuilder<Quote>()
    .column({
        key: 'tuid',
        label: 'Quote',
        isSortable: true,
        getValue: item => item.tuid,
    })
    .column({
        key: 'products',
        label: 'Products',
        isSortable: false,
        getValue: item => item.totalItems,
    })
    .column({
        key: 'total_price',
        label: 'Total Price',
        isSortable: true,
        // TODO should this value be different for different locales, ie should totalSellPriceEx be used?
        getValue: item => item.totalSellPriceInc,
        renderValue: value => `$${value.toFixed(2)}`,
    })
    .build();

export function QuoteTab({ model }: { model: DealDetail }) {
    const query = quotesApi.useQuoteListQuery(model.id);

    const [showAddQuoteModal, setShowAddQuoteModal] = React.useState(false);

    return (
        <>
            <MyButton
                label="Add Quote"
                IconLeft={Icons.Plus}
                buttonType="Accent"
                onClick={() => setShowAddQuoteModal(true)}
            />

            <DataTable
                columns={COLUMNS}
                isLoading={query.isLoading}
                isError={query.isError}
                data={query.data}
                rowLinkTo={quote => `quotes/${quote.id}`}
            />
            {showAddQuoteModal && (
                <QuoteCreateModal
                    dealId={model.id}
                    close={() => setShowAddQuoteModal(false)}
                />
            )}
        </>
    );
}
